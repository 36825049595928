<template>
  <div class="position-fixed" v-show="showDialog"
       style="left: 50%;top: 30%;transform: translate(-50%, -30%) scale(.9);z-index: 99999;">
    <div class="bdr-20  w-400 h-600 column position-relative"
         style="box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);background-color: #fcfcfc">
      <div v-if="loadingLinkCard" style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        <i class="el-icon-loading fs-30"></i>
      </div>
      <iframe v-show="!showList" class="w-fill flex-1 bdr-20" :srcdoc="srcdoc" frameborder="0"></iframe>
      <div v-show="showList" class="w-fill flex-1 bdr-20 pd-t-60 hide-scrollbar pd-l-12 pd-r-12"
           style="overflow-y: scroll">
        <el-row :gutter="12">
          <el-col :span="12" v-for="(card, index) in outRefCardList" :key="card.cardId">
            <div class="mg-b-12 bg-f bdr-10 h-210 pd-16 cursor-pointer shadow-2 position-relative"
                 style="overflow-y: hidden;"
                 @click="onCardClick(index,card)">
              <div v-if="card.fld1 !== ''" class="text-ellipsis-2 fs-16 c-0 text-strong mg-b-8"
                   v-html="card.fld1"></div>
              <div class="c-9 fs-14" :class="card.image ===''? 'text-ellipsis-5':'text-ellipsis-1'"
                   v-html="card.fld2"></div>
              <img v-if="card.image" class="bdr-6 w-fill h-80 mg-t-5 mg-b-5" style="object-fit: cover" :src="card.image"
                   onerror="onImageLoadErr(this)"/>
              <div class="justify-content-space-between fs-12 c-9 position-absolute b-0 l-0 r-0 pd-10">
                <div>
                  <div class="align-items-center" v-if="holdMap[card.status]">
                    <svg-icon :iconClass="holdMap[card.status].icon"></svg-icon>
                    <div class="card-type-text">{{ holdMap[card.status].name }}</div>
                  </div>
                </div>
                <div class="card-created-time" v-if="card.lapses > 3">
                  <svg-icon iconClass="forget"></svg-icon>
                  忘记{{ card.lapses }}次
                </div>
                <div class="card-created-time" v-else>{{ modifyTime(card.nmod) }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="position-absolute l-0 r-0 t-0"
           style="border-top-left-radius: 20px;border-top-right-radius:20px">
        <div class="position-relative">
          <div class="pd-15 position-absolute r-0 t-0">
            <i class="el-icon-close fs-18 cursor-pointer" @click="onCloseClick"></i>
          </div>
          <div class="c-9 justify-content-center pd-t-10 pd-b-10">
            <div class="align-items-center bdr-8 bg-f9f shadow-2 fs-14">
              <div class="cursor-pointer bdr-8 pd-l-20 pd-r-20 pd-t-5 pd-b-5"
                   @click="changeTab(0)"
                   :class="currentTab ===0 ?'shadow-2 bg-f c-0':''">
                我@{{inRefCardIds.length}}张
              </div>
              <div class="cursor-pointer bdr-8 pd-l-20 pd-r-20 pd-t-5 pd-b-5"
                   @click="changeTab(1)"
                   :class="currentTab ===1 ?'shadow-2 bg-f c-0':''">
                @我{{outRefCardList.length}}张
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute b-0 l-0 r-0"
           v-show="(currentTab === 0 && inRefCardIds.length > 0) || (currentTab === 1 && !showList && outRefCardList.length > 0)">
        <div class="justify-content-space-between c-9">
          <div class="pd-15"><i class="el-icon-arrow-left fs-20 cursor-pointer" @click="onLeftClick"></i></div>
          <div class="pd-15 position-relative  align-items-center">
            <div>{{currentIndex+1}} / {{currentTab === 0?inRefCardIds.length:outRefCardList.length}}</div>
            <svg-icon icon-class="card_edit_menu" class="position-absolute cursor-pointer" style="right:-80px" @click="onEditCard"></svg-icon>
          </div>
          <div class="pd-15"><i class="el-icon-arrow-right fs-20 cursor-pointer" @click="onRightClick"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardRender from "../../libs/CardRender";
import request from "../../libs/api.request";
import dayjs from 'dayjs'

export default {
  name: "LinkCardViewer",
  props: {
    mainCardId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modelList: [],
      innerSrcdoc: "",
      loadingLinkCard: false,
      showDialog: false,
      showList: false,
      srcdoc: '',
      inRefCardIds: [],
      outRefCardList: [],
      showCardId: 0,
      currentTab: 0,
      currentIndex: 0,
      holdMap: {
        1: {icon: 'new_card', id: 1, name: '新卡'},
        2: {icon: 'handle', id: 2, name: '已掌握'},
        3: {icon: 'studying', id: 3, name: '记忆中'},
        4: {icon: 'no_review', id: 5, name: '不复习'}
      },
      card:{},
      model:{}
    }
  },
  watch: {
    mainCardId() {
      // console.log("mainCardId change", this.mainCardId);
      this.inRefCardIds = [];
      this.outRefCardList = [];
    }
  },
  computed: {
    modifyTime() {
      return (time) => {
        return dayjs(time).format('MM.DD HH:mm')
      }
    },
  },
  methods: {
    close() {
      this.showDialog = false;
    },
    onCloseClick() {
      if (this.currentTab === 1 && !this.showList) {
        this.showList = true;
      } else {
        this.showDialog = false
      }
    },
    async open() {
      if (this.showDialog) {
        return;
      }
      this.showDialog = true;
      this.loadingLinkCard = true;
      if (this.inRefCardIds.length === 0 && this.outRefCardList.length === 0) {
        let res1 = await request.request({
          url: "/card/allByRef",
          params: {cardId: this.mainCardId},
        });
        this.inRefCardIds = res1.inRefCardIds;
        this.outRefCardList = res1.outRefCardList;
      }
      this.currentIndex = 0;
      if (this.inRefCardIds.length === 0) {
        this.currentTab = 1;
        this.showList = true;
        this.loadingLinkCard = false;
      } else {
        this.currentTab = 0;
        this.showCardId = this.inRefCardIds[this.currentIndex];
        this.getAndShowCard();
      }
    },
    onEditCard(){
      this.$emit('editorLinkCard',this.card,this.model)
    },
    onLeftClick() {
      if (this.currentIndex <= 0) {
        return;
      }
      this.currentIndex--;
      this.showCardId = this.currentTab === 0 ? this.inRefCardIds[this.currentIndex] : this.outRefCardList[this.currentIndex].cardId;
      this.getAndShowCard();
    },
    changeTab(tab) {
      this.currentIndex = 0;
      this.currentTab = tab;
      if (tab === 1) {
        if (this.outRefCardList.length === 0) {
          this.showList = false;
          this.noRefCard();
        } else {
          this.showList = true;
        }
      } else {
        this.showList = false;
        if (this.inRefCardIds.length > 0) {
          this.showCardId = this.inRefCardIds[this.currentIndex];
          this.getAndShowCard();
        } else {
          this.noRefCard();
        }
      }

    },
    noRefCard() {
      if (this.currentTab === 0) {
        this.srcdoc = "<html><div style='text-align: center;color: #999999;position: absolute;top: 48%;left: 0;right: 0'>没有我@的卡片</div></html>";
      } else {
        this.srcdoc = "<html><div style='text-align: center;color: #999999;position: absolute;top: 48%;left: 0;right: 0'>没有@我的卡片</div></html>";
      }
    },
    onCardClick(index, card) {
      this.showList = false;
      this.currentIndex = index;
      this.showCardId = card.cardId;
      this.getAndShowCard();
    },
    onRightClick() {
      let length = this.currentTab === 0 ? this.inRefCardIds.length : this.outRefCardList.length;
      if (this.currentIndex >= length - 1) {
        return;
      }
      this.currentIndex++;
      this.showCardId = this.currentTab === 0 ? this.inRefCardIds[this.currentIndex] : this.outRefCardList[this.currentIndex].cardId;
      this.getAndShowCard();
    },
    async postMessageListener(e) {
      // console.log("postMessageListener");
      // console.log(e);
      // console.log(e.data.act);
      if (e.data && e.data.act === 'linkCard') {
        this.showDialog = true;
        if (this.inRefCardIds.length === 0 && this.outRefCardList.length === 0) {
          let res1 = await request.request({
            url: "/card/allByRef",
            params: {cardId: this.mainCardId},
          });
          console.log(res1);
          this.inRefCardIds = res1.inRefCardIds;
          this.outRefCardList = res1.outRefCardList;
        }

        this.showCardId = parseInt(e.data.cardId);
        this.currentTab = 0;
        this.showList = false;
        console.log(this.inRefCardIds);
        console.log(this.showCardId);
        console.log(this.inRefCardIds.indexOf(this.showCardId));
        this.currentIndex = this.inRefCardIds.indexOf(this.showCardId);
        this.getAndShowCard()
      }
    },
    async getAndShowCard() {
      this.loadingLinkCard = true;
      this.srcdoc = '';
      try {
        let res = await request.request({
          url: "/card/detail",
          params: {cardId: this.showCardId},
        });
        this.card = res
        this.loadingLinkCard = false;
        // document.getElementById('link-card-chapter-name').innerText = res.chapterName;
        let model = await this.getModelDetail(res.modelId);
        this.model = model
        this.srcdoc = CardRender('afmt', res, model);
        this.loadingLinkCard = false;
      } catch (e) {
        console.log(e);
        if (e.code === 404) {
          this.srcdoc = "<html><div style='text-align: center;color: #999999;position: absolute;top: 48%;left: 0;right: 0'>卡牌不存在或已删除</div></html>";
        }
      }
      this.loadingLinkCard = false;
    },
    async getModelDetail(modelId) {
      if (this.modelList != null && this.modelList != undefined) {
        for (let value of this.modelList) {
          if (value.modelId === modelId) {
            return value
          }
        }
      }
      let res = await request.request({
        url: "model/detail",
        params: {
          modelId: modelId
        }
      });
      this.modelList.push(res)
      return res;
    },
  },
  mounted() {
    window.addEventListener("message", this.postMessageListener, true);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.postMessageListener, true);
  }
}
</script>

<style scoped>

</style>
