<template>
  <div>
    <div class="card-editor-container">
      <div class="card-editor-head">
        <div class="card-num">{{ targetChapter.name || card.chapterName || currentChapter.name}}</div>
      </div>
      <div class="card-editor-content-box">
        <div class="card-editor-content" :class="isPreview ? 'previewWidth' : 'currentWidth'"
             :style="handleClientSize()">
          <div class="card-editor-img" @click="closeEditor">
            <svg-icon iconClass="cardviwer_close" class="w-24 h-24"></svg-icon>
          </div>
          <div class="delete-img" @click="deleteCard">
            <svg-icon iconClass="card-edit-delete" class="w-24 h-24"></svg-icon>
          </div>
          <div class="card-editor-content-head" style="height: 100%;">
            <div class="position-absolute t-0 l-0 r-0 bg-f bdr-12" style="z-index: 999">
              <editor-toolbar :tools="toolsMap[this.currentEditorIndex]" class="mg-l-5 pd-t-10"
                              @toolClick="onToolClick">
              </editor-toolbar>
            </div>
            <div class="hide-scrollbar my-editor-box">
              <div class="h-50"></div>
              <div v-for="v, k in editors" class="pd-b-10 editor" v-show="tpl[k] && tpl[k].display !== 'none'" :key="k"
                   :class="model.modelId == 1 || model.modelId == 3 ? 'official-classic' : ''">
                <div class="display-flex" style="flex-wrap: wrap;">
                  <div v-if="tpl[k]" :class="model.hideTitle ? 'display-none' : ''"
                       class="fs-16 mg-l-12 lh-36 h-36 text-strong" style="white-space: nowrap;">{{ tpl[k].name }}
                  </div>
                </div>
                <div class="pd-t-5" :class="k === 0 && duplicateNotice ? 'duplicate-notice' : ''">
                  <div :id="'card-edit-' + k"></div>
                </div>
                <div v-if="k < tpl.length - 1" style="border-bottom: 1px solid #f1f1f1;margin: 0 10px"></div>
              </div>
            </div>
            <!-- 预览区域 -->
            <!-- <div class="card-preview" v-if="isPreview">
              <div class="card-preview-head">
                <div class="preview-text">卡片预览</div>
                <img @click="closePreview" src="../assets/images/icons_action_PC_light_close_24pt@2x.png"
                  class="close-img" />
              </div>
              <div class="card-preview-content" :style="previewHeight">
                <div class="card-preview-content-head">
                  <div class="text">{{ card.chapterName }}</div>
                  <img src="../assets/images/icons_nav_PC_light_more_24pt@2x.png" class="img" />
                </div>
                <div class="card-preview-content-center">
                  <iframe id="iframe" :srcdoc="srcdoc" frameborder="0"
                    style="width: 100%;border: none;height: 100%;"></iframe>
                </div>
                <div class="card-preview-show-answer">
                  <div v-if="true" class="show-end" @click="showAnswer">{{ cardStatus === 'qfmt' ? '显示答案' : '显示正面' }}
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- 底部选择模版和预览图标区域 -->
          <div class="card-editor-content-footer">
            <div class="left-icon">
              <div class="card-editor-footer-img" @click="openSelectChapterOption" v-if="isShowSelectChpater">
                <svg-icon iconClass="card_edit_box" class="w-20 h-20 mg-r-8"></svg-icon>
                <div style="font-size:12px">{{ targetChapter.name || card.chapterName || currentChapter.chapterName ||
                  '选择卡牌盒'
                  }}
                </div>
              </div>
              <div class="card-editor-footer-img" @click="openSelectModel">
                <svg-icon iconClass="card_template" class="w-20 h-20 mg-r-8"></svg-icon>
                <div style="font-size:12px">{{ model.name }}</div>
              </div>
              <!-- <div class="card-editor-footer-img" @click="showPreview">
                <img v-if="isPreview" src="../assets/images/icons_outline_action_light_eye_24pt@2x.png"
                  class="editor-model-img" />
                <img v-else src="../assets/images/icons_outline_action_light_eye1_24pt@2x.png" class="editor-model-img" />
                <div style="font-size:12px" :style="{ color: isPreview ? '#FFB700' : '' }">预览</div>
              </div> -->
              <div class="card-editor-footer-img" @click="onImportDataClick">
                <svg-icon iconClass="more_import1" class="w-20 h-20 mg-r-8"></svg-icon>
                <div class="fs-12">批量制卡</div>
              </div>
            </div>
            <div class="right-save-text" @click="saveAddCard"
                 :style="{ color: hasSave ? 'rgba(32, 32, 32, 0.4)' : '#202020' }">{{ saveText }}
            </div>
          </div>
        </div>
        <!-- 上一页 -->
        <div v-show="currentEditorCardIndex > 0 && isShowLeftOrRightBtn && !isRightAdd && !isLeftAdd"
             class="card-editor-toggle-icon pre" @click="showPreCard">
          <svg-icon iconClass="cardviwer_left_arrow" class="w-24 h-24 mg-l-16"></svg-icon>
        </div>
        <!-- 下一页 -->
        <div
            v-show="editorCardList !== null && editorCardList.length > 0 && (currentEditorCardIndex < editorCardList.length - 1) && isShowLeftOrRightBtn && !isRightAdd && !isLeftAdd"
            class="card-editor-toggle-icon next" @click="showNextCard">
          <svg-icon iconClass="cardviwer_right_arrow" class="w-24 h-24 mg-l-32"></svg-icon>
        </div>
      </div>
    </div>
    <select-model ref="selectModel" @selectModel="selectModel" @openImportAnki="openImportAnki"
                  :showSelectModel.sync="isShowSelectModel" @updateShowSelectModel="updateShowSelectModel"
                  v-if="isShowSelectModel"
                  :appendBody="isFromSearch ? false : true">
    </select-model>
    <chapter-options ref="chapterOptions" @selectChapter="selectChapter">
    </chapter-options>
    <card-link ref="cardLink" @onSelectedCard="onSelectLinkCard"></card-link>
    <image-cover-editor v-if="doImageCoverSrc" :doImageCoverSrc="doImageCoverSrc" :doImageCoverSvg="doImageCoverSvg"
                        @cancelImageEdit="cancelImageEdit" @finishImageCover="finishImageCover">
    </image-cover-editor>
  </div>
</template>

<script>
import SelectModel from './SelectModel.vue';
import request from '../libs/api.request'
import CardRender from '../libs/CardRender'
import EditorToolbarUtil from '../libs/EditToolbarUtil'
import EditorToolbar from './common/EditorToolbar'
import chapterOptions from './ChapterOptions.vue'
import {showMessage} from '../libs/message';
import CardLink from "./common/CardLink";
import ImageCoverEditor from "./common/ImageCoverEditor";
import {mapGetters} from 'vuex';
import {debounce} from '../libs/analyse';

export default {
  props: {
    // 编辑中的卡牌列表
    editorCardList: {
      type: Array,
      default: () => []
    },
    currentEditorCard: {
      type: Object,
      default: () => {
      }
    },
    //默认是编辑卡牌
    isEditorCard: {
      type: Boolean,
      default: true
    },
    // 向左添加
    isLeftAdd: {
      type: Boolean,
      default: false
    },
    // 向右边添加
    isRightAdd: {
      type: Boolean,
      default: false
    },
    // 卡牌列表中点击编辑的卡牌的索引
    currentCardIndex: {
      type: Number,
      default: 0
    },
    isShowLeftOrRightBtn: {
      type: Boolean,
      default: true
    },
    // 第一张卡牌的模版
    firstCardModel: {
      type: Object,
      default: () => {
      }
    },
    isShowSelectChpater: {
      type: Boolean,
      default: true
    },
    // 当前选择要添加的卡牌盒
    currentChapter: {
      type: Object,
      default: () => {
      }
    },
    isEditLinkCard: {
      type: Boolean,
      default: false
    },
    // 是否在cardSearch里面，如果是就给select-model append-to-body=false
    isFromSearch: {
      default: Boolean,
      default: false
    }
  },
  components: {
    CardLink,
    SelectModel, chapterOptions, EditorToolbar, ImageCoverEditor
  },
  data() {
    return {
      isSaving: false,
      isPreview: false,
      clienHeight: '',
      screenWidth: '',
      padHeight: 176,// 编辑器距离上下的边距 96 + 编辑预览那一栏的高度80
      modelIconHeight: 360, // 80+ 90 + margin-top:56
      // 编辑器相关的
      cardStatus: 'qfmt',//qfmt 正面  afmt 背面
      currentEditorIndex: '',
      editors: [],
      toolsMap: [],
      srcdoc: '',
      model: {},
      card: this.currentEditorCard,
      hasChange: false,
      hasSaveCard: false,
      hasDeleteCard: false,// 是否点击过删除卡牌
      hasSave: false, // 控制按钮是已保存还是保存以及按钮文字颜色
      saveText: '保存',
      tpl: [], // 渲染编辑器内容
      currentEditorCardIndex: this.currentCardIndex, // 编辑中的卡牌的索引
      modelList: [],
      targetChapter: {},//
      hasMove: false,// 是否移动过
      linkCardEditorInfo: {
        index: 0,
        selection: null
      },
      queryWordTimeout: null,
      doImageCoverElement: null,
      doImageCoverEditor: null,
      doImageCoverSrc: '',
      isShowSelectModel: false,
      duplicateNotice: false,
      queryDuplicatesTimer: null
    }
  },
  methods: {
    queryDuplicates(time) {
      if (this.queryDuplicatesTimer) {
        clearTimeout(this.queryDuplicatesTimer);
      }

      this.queryDuplicatesTimer = setTimeout(() => {
        request.request({
          url: 'card/queryDuplicates',
          params: {value: this.tpl[0].value}
        }).then((data) => {
          if (data.find) {
            this.duplicateNotice = true;
          }
        })
      }, time || 2000)
    },
    onSelectLinkCard(data) {
      console.log("onSelectLinkCard", data);
      this.editors[this.linkCardEditorInfo.index].model.change(writer => {
        let html = "<span class='link-card' data-lcid='" + data.card.cardId + "'>@" + data.card.keyword.trim() + "</span> ";
        let viewFragment = this.editors[this.linkCardEditorInfo.index].data.processor.toView(html);
        let modelFragment = this.editors[this.linkCardEditorInfo.index].data.toModel(viewFragment);
        this.editors[this.linkCardEditorInfo.index].execute('delete', {selection: this.linkCardEditorInfo.selection});
        setTimeout(() => {
          this.editors[this.linkCardEditorInfo.index].model.insertContent(modelFragment, this.linkCardEditorInfo.selection);
          this.editors[this.linkCardEditorInfo.index].model.insertContent(writer.createText(" "));
          this.editors[this.linkCardEditorInfo.index].editing.view.focus();
        }, 100);
      });
    },
    openSelectChapterOption() {
      this.$refs.chapterOptions.open()
    },
    selectChapter(chapter) {
      if (this.targetChapter.chapterId !== chapter.chapterId) {
        this.saveText = '保存'
        this.hasSave = false
      }
      this.targetChapter = chapter
      this.$refs.chapterOptions.close()
    },
    onImportDataClick() {
      this.$emit('openImportData', this.currentChapter)
    },
    handleHasChangeSave(callback) {
      if (this.hasChange) {
        request.request({
          url: '/card/save',
          data: {
            cardId: this.card.cardId,
            chapterId: this.card.chapterId,
            modelId: this.model.modelId,
            flds: JSON.stringify(this.tpl)
          },
          method: 'post'
        }).then(res => {
          this.$emit('saveEditSuccess', res)
          this.hasChange = false
          showMessage({message: '已保存修改', type: "success"})
          callback()
        })
      } else {
        callback()
      }
    },
    // 上一张
    showPreCard() {
      this.targetChapter = {}
      this.handleHasChangeSave(() => {
        if (this.currentEditorCardIndex === 0) {
          showMessage({message: '当前是第一张'})
        } else {
          this.currentEditorCardIndex--
          this.getCardDetail()
        }
      })
    },
    // 下一张
    showNextCard() {
      this.targetChapter = {}
      this.handleHasChangeSave(() => {
        if (this.currentEditorCardIndex < this.editorCardList.length - 1) {
          this.currentEditorCardIndex++
          this.getCardDetail()
        } else {
          setTimeout(() => {
            if (this.currentEditorCardIndex == this.editorCardList.length - 1) {
              showMessage({message: '当前是最后一张'})
            }
          }, 500)
        }
      })
    },
    getTagItems(queryText) {
      console.log("queryText = " + queryText);
      return new Promise((resolve, reject) => {
        request.request({
          url: '/card/queryTags',
          params: {query: queryText}
        }).then(data => {
          resolve(data.list)
        }).catch(e => {
          reject(e)
        })
      });
    },
    initEditor() {
      console.log(this.isEditorCard);
      // this.currentEditorIndex = '';
      // 默认是编辑卡牌，如果是新增卡牌
      if (!this.isEditorCard) {
        this.card.flds = []
      }
      this.tpl = [];
      this.model.flds.forEach((v, k) => {
        if (this.card.flds[k] === undefined) {
          this.card.flds[k] = '';
        }
        this.tpl.push({name: v.name, value: this.card.flds[k], placeholder: v.placeholder, display: v.display});
      });
      this.tpl.forEach((v, k) => {
        if (v.value) {//这里是修复之前编辑器把头尾去掉的bug
          let end = v.value.indexOf('</p>');
          if (end && v.value.indexOf('<p>') > end) {
            v.value = "<p>" + v.value;
          }
          let lastStar = v.value.lastIndexOf("<p>");
          if (lastStar && v.value.indexOf('</p>') < lastStar) {
            v.value += "</p>";
          }
          // if (v.value.indexOf('≯#') > -1 && v.value.indexOf('#≮') > -1) {
          // setTimeout(() => {
          // this.$message.error('【' + v.name + '】已被加密，可能无法编辑');
          // }, k * 400);
          // }
        }
        let placeholder = "在此输入内容";
        if (v.placeholder) {
          placeholder = v.placeholder;
        }
        if (this.editors[k]) {
          console.log(">>>>>");
          console.log(v.value);
          this.editors[k].setData(v.value);
          setTimeout(() => {
            document.getElementsByClassName('ck-placeholder')[k].setAttribute('data-placeholder', placeholder);
          }, 100);
        } else {
          AnkiEditor.create(document.querySelector('#card-edit-' + k), {
            placeholder: placeholder,
            simpleUpload: {
              uploadUrl: request.baseUrl + '/common/editorUploadImg',
              headers: {Authorization: this.$cookies.get('authToken')}
            },
            mention: {
              feeds: [
                {
                  marker: '#',
                  feed: this.getTagItems
                }
              ]
            },
          }).then(editor => {
            Object.keys(this.toolsMap[k]).forEach(name => {
              const command = editor.commands.get(name);
              if (command) {
                command.on('change:value', () => {
                  console.log(name + " change:value = " + command.value);
                  this.toolsMap[k][name].active = command.value;
                });
              }
            });
            editor.matchedLinkCard = () => {
              this.linkCardEditorInfo.index = this.currentEditorIndex;
              this.linkCardEditorInfo.selection = this.editors[this.currentEditorIndex].model.document.selection;
              this.$refs.cardLink.open();
            }
            //图标涂鸦编辑器
            editor.imageCover = (selectedElement) => {
              this.doImageCoverElement = selectedElement;
              this.doImageCoverEditor = editor;
              let imageElement = null;
              let hasCover = false;
              if (selectedElement.name === 'imageCover') {
                hasCover = true;
                imageElement = selectedElement.parent;
              } else {
                imageElement = selectedElement;
              }

              this.showImageCoverEditor(imageElement.getAttribute('src'), hasCover ? selectedElement.getAttribute('draw') : '');
            };
            v.value = v.value.replace(new RegExp("<o:p></o:p>", "gm"), "");
            editor.setData(v.value);
            this.editors[k] = editor;
            editor.model.document.on('change', () => {
              if(this.isEditorCard){
                this.autoSave()
              }
              this.hasSave = false
              this.saveText = '保存'
              let newHtml = editor.getData();
              if (newHtml.length > 6) {
                if (newHtml.substr(0, 3).toLowerCase() === '<p>'
                  && newHtml.substr(newHtml.length - 4).toLowerCase() === '</p>'
                  && newHtml.indexOf('</p>') === newHtml.length - 4) {
                  newHtml = newHtml.substr(3, newHtml.length - 7)
                }
              }
              let match = '<p>&nbsp;</p>';
              do {
                let lastIndex = newHtml.lastIndexOf(match);
                if (lastIndex > -1 && lastIndex === newHtml.length - match.length) {
                  newHtml = newHtml.substr(0, newHtml.length - match.length);
                } else {
                  break;
                }
              } while (true);
              if (newHtml !== this.tpl[k].value) {
                this.tpl[k].value = newHtml.replace(new RegExp("<o:p></o:p>", "gm"), "");
                if (k === 0 && !this.isEditorCard) {
                  this.duplicateNotice = false;
                  this.queryDuplicates(2500);
                }
                if (!this.card.id) {
                  this.card.flds[k] = newHtml;
                }
                // this.hasChange = true;
                if (this.model.modelId == 5 && k === 0 && !this.isEditorCard) {
                  this.startQueryWord(newHtml);
                }
                // this.checkAkCoverListener();
              }
              if (newHtml === '') {
                setTimeout(() => {
                  document.getElementsByClassName('ck-placeholder')[k].setAttribute('data-placeholder', this.tpl[k].placeholder || '在此处输入内容');
                }, 80);
              }
            })
            editor.editing.view.document.on('focus', () => {
              if (this.currentEditorIndex === 0 &&
                k !== 0
                && this.tpl[0].value
                && !this.isEditorCard
              ) {
                this.queryDuplicates(10);
              }
              this.currentEditorIndex = k;
            })
            editor.editing.view.document.on('blur', () => {
            })
          }).catch(error => {
            console.error(error);
          });
        }
      })

      // this.checkAkCoverListener();
    },
    onToolClick(e) {
      if (e.tool.name === 'image') {
        if (!this.isVip && this.vipLimitData && this.vipLimitData.cardAddImg.limit <= this.vipLimitData.cardAddImg.use) {
          this.$store.commit('changeShowIntroduceVip', true)
          return
        }
      }
      EditorToolbarUtil.handlerEvent(e, this.toolsMap, this.editors, this.currentEditorIndex);
    },
    onAkCoverClick(e) {
      if (e.target.style.color == 'rgb(0, 0, 0)') {
        e.target.style.color = '#FFE5B4';
      } else {
        e.target.style.color = '#000000';
      }
    },
    checkAkCoverListener() {
      setTimeout(() => {
        let test = document.getElementsByClassName('ak-cover');
        for (let i = 0; i < test.length; i++) {
          test[i].removeEventListener('click', this.onAkCoverClick)
          test[i].addEventListener('click', this.onAkCoverClick)
        }
      }, 300);
    },
    autoSave:debounce(function(){
      request.request({
        url: '/card/save',
        data: {
          cardId:this.card.cardId,
          chapterId: this.targetChapter.chapterId || this.card.chapterId || this.currentChapter.chapterId,
          modelId: this.model.modelId,
          position:0,
          flds: JSON.stringify(this.tpl)
        },
        method: 'post'
      }).then(res =>{
        console.log('autoSaveSuccess');
      })
    },1000,false),
    // 保存添加卡牌
    saveAddCard: debounce(function () {
      if (this.isSaving === true) return
      console.log(this.currentChapter);
      if (!this.model || !this.model.modelId) {
        showMessage({message: '请选择模版！', type: 'error'})
        return
      }
      this.saveText = '保存中...'
      let flds = [];
      this.tpl.forEach((v, k) => {
        flds.push(v.value)
      });
      let isEmpty = true;
      for (let i = 0; i < flds.length; i++) {
        if (flds[i].trim().length > 0) {
          isEmpty = false;
          break;
        }
      }
      if (isEmpty) {
        showMessage({message: '你什么都没写~', type: 'warning'})
        this.saveText = '保存';
        return;
      }
      if (this.model.modelId == 5) {
        if (!this.isVip && this.vipLimitData && this.vipLimitData.wordCard.limit <= this.vipLimitData.wordCard.use) {
          this.$store.commit('changeShowIntroduceVip', true)
          return
        }
      }
      if (!this.targetChapter.chapterId && !this.currentChapter.chapterId && !this.card.chapterId) {
        showMessage({message: '请选择卡牌盒', type: 'warning'})
        this.saveText = '保存';
        return;
      }
      let cardId = ''
      let position = ''
      // 如果是左右添加，则是添加卡牌
      if (this.isLeftAdd || this.isRightAdd) {
        cardId = new Date().getTime() + '001'
        if (this.isLeftAdd) {
          // 左侧添加，position 是当前卡牌的下的索引在当前组下的索引
          for (let i = this.currentEditorCardIndex; i < this.$parent.chapterDetailList.length; i--) {
            if (this.$parent.chapterDetailList[i].itemType === 1) {
              position = this.currentEditorCardIndex - i
              break
            }
          }
        } else {
          // 右侧添加，position 是当前卡牌的下的索引在当前组下的索引 + 1
          for (let i = this.currentEditorCardIndex; i < this.$parent.chapterDetailList.length; i--) {
            if (this.$parent.chapterDetailList[i].itemType === 1) {
              position = this.currentEditorCardIndex - i + 1
              break
            }
          }
        }
      } else {
        if (this.isEditorCard) {
          // 编辑卡片
          cardId = this.card.cardId
          console.log('编辑');
        } else {
          // 新增卡片
          cardId = (new Date().getTime() + '001')
          console.log('新增');
        }
        position = 0 // position是0,默认插入到尾部
      }
      this.isSaving = true
      request.request({
        url: '/card/save',
        data: {
          cardId,
          chapterId: this.targetChapter.chapterId || this.card.chapterId || this.currentChapter.chapterId,
          modelId: this.model.modelId,
          position,
          flds: JSON.stringify(this.tpl)
        },
        method: 'post'
      }).then(res => {
        this.isSaving = false
        if (Array.isArray(res)) {
          // 新增正反面卡的时候
          res[0]['itemType'] = 2
          res[1]['itemType'] = 2
        } else {
          res['itemType'] = 2
        }
        if (!this.isEditorCard) {
          // 如果是新增卡牌则清空编辑器
          this.clearTpls()
        }
        if (!this.isVip) {
          this.$store.dispatch('modifyUserInfo')
        }
        this.saveText = '已保存'
        this.hasSave = true
        this.hasSaveCard = true
        this.hasChange = false
        if (this.isShowSelectChpater && this.targetChapter.chapterId) {
          showMessage({message: '保存成功，刷新后可见！', type: 'success'})
        } else {
          showMessage({message: '保存成功', type: 'success'})
          if (!this.isEditLinkCard) {
            this.$emit('saveEditSuccess', res, this.currentEditorCardIndex, this.isEditorCard)
          }
        }
        //currentEditorCardInde传回去是为了更新卡牌列表里被修改过的卡牌
      }).catch(err => {
        showMessage({message: err.message, type: 'error'})
      })
    }, 1000, true),
    getCardDetail() {
      this.card = this.editorCardList[this.currentEditorCardIndex]
      request.request({
        url: '/card/detail',
        params: {
          cardId: this.card.cardId
        }
      }).then(res => {
        this.card = res
        this.getModelDetail(this.initEditor)
      }).catch(err => {
        showMessage({message: err, type: 'error'})
      })
    },
    // 清空输入的内容
    clearTpls() {
      this.tpl.forEach((v, k) => {
        this.editors[k].setData('');
      })
      this.initEditor()
      this.saveText = '保存'
    },
    selectModel(model) {
      this.model = model
      this.$store.commit('setSelectedModel', model)
      this.initEditor()
    },
    getModelDetail(callback) {
      if (this.modelList != null && this.modelList != undefined && this.modelList.length > 0) {
        for (let value of this.modelList) {
          if (value.modelId === this.card.modelId) {
            this.model = value
            callback()
            return
          }
        }
        callback()
      }
      request.request({
        url: "model/detail",
        params: {
          modelId: this.card.modelId
        }
      }).then(res => {
        this.model = res
        this.modelList.push(res)
        callback()
      })
    },
    showAnswer() {
      if (this.cardStatus === 'qfmt') {
        this.cardStatus = 'afmt';
      } else {
        this.cardStatus = 'qfmt';
      }
      this.rendHtml();
    },
    // 卡牌内容渲染
    rendHtml() {
      this.srcdoc = CardRender(this.cardStatus, this.card, this.model);
      this.$nextTick(() => {
        setTimeout(() => {
          let body = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByTagName('body')[0];
          // console.log(body);
          if (body == undefined) {
            this.bgcColor = 'rgba(255, 255, 255)'
            return
          }
          let color = window.getComputedStyle(body, null).backgroundColor;
          if (color == 'rgba(0, 0, 0, 0)') {
            color = 'rgba(255, 255, 255)'
          }
          this.bgcColor = color
        }, 200)
      })
    },
    deleteCard() {
      this.$confirm('是否删除该卡牌?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/card/delete',
              method: 'post',
              data: {cardId: this.card.cardId,}
            }).then((res) => {
              showMessage({message: '删除成功！', type: 'success'})
              done();
              this.hasDeleteCard = true
              this.$emit('deleteCardSuccess', this.currentEditorCardIndex)
              if (this.editorCardList.length == 0) {
                this.$router.go(0)
              }
              this.getCardDetail()
            }).catch((e) => {
              showMessage({message: e, type: 'error'})
            })
          } else {
            done();
          }
        }
      })
    },
    closeEditor() {
      this.targetChapter = {}
      this.$emit('closeEditor', this.hasSaveCard, this.hasDeleteCard, this.hasMove)
    },
    showPreview() {
      if (this.isPreview !== true) {
        this.rendHtml()
      }
      this.isPreview = !this.isPreview
    },
    closePreview() {
      this.isPreview = false
    },
    openSelectModel() {
      this.isShowSelectModel = true
      // this.$refs.selectModel.open();
    },
    handleResize() {
      this.screenWidth = window.document.documentElement.clientWidth
      this.clienHeight = window.document.documentElement.clientHeight
    },
    handleClientSize() {
      let height = this.clienHeight - this.padHeight
      let width = (this.clienHeight - this.padHeight) * 1.8
      if (width > this.screenWidth - 152) {
        width = this.screenWidth - 152
      }
      return `height:${height}px;width:${width}px`
    },
    handleKeyDown(e) {
      if ((e.keyCode === 83 && e.ctrlKey) || (e.metaKey && e.keyCode === 83)) {
        this.saveAddCard()
        e.preventDefault();
        return false
      }

      if ((e.keyCode === 116) || (e.metaKey && e.keyCode === 82)) {
        this.$confirm('未保存的内容将会丢失，是否刷新页面？', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              done();
              if (action === 'confirm') {
                location.reload()
              }
            }
          }
        );
        e.preventDefault();
        return false
      }
    },
    startQueryWord(word, timeout) {
      if (this.model.modelId != 5 || word.length > 500) {
        return;
      }

      if (this.queryWordTimeout != null) {
        clearTimeout(this.queryWordTimeout);
      }

      this.editors[1].setData("释义查询中...");
      this.queryWordTimeout = setTimeout(() => {
        this.queryWordTimeout = null;
        request.request({
          url: 'card/queryWord',
          params: {word: word}
        }).then((data) => {
          this.editors[1].setData(data.explain);
          this.editors[3].setData(data.pronun);
          this.editors[4].setData(data.example);
        })
      }, timeout || 1000)
    },
    showImageCoverEditor(src, svg) {
      this.doImageCoverSrc = src;
      this.doImageCoverSvg = svg;
    },
    cancelImageEdit() {
      this.doImageCoverSrc = '';
    },
    finishImageCover(svg) {
      this.doImageCoverEditor.model.change(writer => {
        let imageElement;
        if (this.doImageCoverElement.name === 'imageCover') {
          imageElement = this.doImageCoverElement.parent;
          writer.remove(this.doImageCoverElement);
          if (svg === '') {
            return;
          }
        } else {
          imageElement = this.doImageCoverElement;
        }

        const newImageCoverElement = writer.createElement('imageCover');
        writer.setAttribute('draw', svg, newImageCoverElement);
        writer.append(newImageCoverElement, imageElement);
      });
      this.doImageCoverSrc = '';
    },
    updateShowSelectModel(v) {
      this.isShowSelectModel = v
    },
    openImportAnki() {
      this.$emit('openImportAnki')
    },
  },
  computed: {
    ...mapGetters(['vipLimitData', 'isVip']),
    contentHight: function () {
      return `height: ${this.clienHeight - this.padHeight}px`
    },
    previewHeight: function () {
      // return `height: ${this.clienHeight - this.padHeight - this.modelIconHeight}px`
      return `height: ${this.clienHeight - this.modelIconHeight}px`
    },
  },
  watch: {
    screenWidth(n) {
      this.screenWidth = n
      this.handleClientSize()
    }
  },
  created() {
    for (let i = 0; i < 50; i++) {
      this.editors.push(null);
      this.toolsMap.push(EditorToolbarUtil.makeToolObj())
    }
    if (Object.keys(this.firstCardModel).length > 0) {
      // 将第一张的卡牌模版，加入到模版列表中
      this.model = this.firstCardModel
      this.modelList.push(this.model)
    }
  },
  mounted() {
    sessionStorage.setItem('CardEditorComponentIsActive', 1)
    this.clienHeight = window.document.documentElement.clientHeight
    this.screenWidth = window.document.documentElement.clientWidth
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
    window.addEventListener('resize', this.handleResize, true)
    this.initEditor()
    window.addEventListener('keydown', this.handleKeyDown, true)
  },
  destroyed() {
    sessionStorage.removeItem('CardEditorComponentIsActive')
    window.removeEventListener('resize', this.handleResize, true)
    window.removeEventListener("keydown", this.handleKeyDown, true);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/mixin.scss";

.card-editor-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  padding-bottom: 24px;

  .card-editor-head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    padding: 0 164px;

    .card-num {
      font-size: 16px;
    }
  }

  .card-editor-content-box {
    position: relative;
    padding: 0 76px;

    .card-editor-content {
      position: relative;
      margin: 0 auto;
      background-color: #fff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 100%;

      .card-editor-img {
        position: absolute;
        top: -56px;
        right: 0;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      .delete-img {
        position: absolute;
        top: -56px;
        left: 0;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      &.currentWidth {
        max-width: 844px;
      }

      &.previewWidth {
        max-width: 1128px;
      }

      .card-editor-content-head {
        position: relative;
        display: flex;

        // flex: 1;
        // justify-content: space-between;
        .my-editor-box {
          flex: 1;
          overflow-y: scroll;
          height: 100%;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        .card-preview {
          margin-top: 56px;
          max-width: 456px;
          padding: 42px 24px 48px;
          background-color: #f2f2f2;

          .card-preview-head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            .preview-text {
              line-height: 14px;
              color: rgba(0, 0, 10, 0.6);
            }

            .close-img {
              width: 24px;
              height: 24px;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .card-preview-content {
            position: relative;
            background-color: #fff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 12px;

            .card-preview-content-head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 26px;
              border-bottom: 1px solid rgba(32, 32, 32, 0.06);
              padding: 0 14px;

              .text {
                font-size: 12px;
                -webkit-transform-origin-x: 0;
                -webkit-transform: scale(0.8);
                transform: scale(0.8);
                color: #00000a;
                @include clamp(1);
              }

              .img {
                width: 12px;
                height: 12px;

                &:hover {
                  cursor: pointer;
                }
              }
            }

            .card-preview-content-center {
              height: calc(100% - 50px);
              padding: 20px 14px;
              overflow: hidden;
            }

            .card-preview-show-answer {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 44px;
              line-height: 44px;
              text-align: center;
              z-index: 999;
              border-top: 1px solid rgba(32, 32, 32, 0.06);

              &:hover {
                cursor: pointer;
              }

              .show-end {
                font-size: 12px;
                -webkit-transform: scale(0.8);
                transform: scale(0.8);
                color: #00000a;
              }
            }
          }
        }
      }

      .card-editor-content-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        padding: 0 24px;
        border-top: 1px solid rgba(32, 32, 32, 0.06);
        background: #fff;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .left-icon {
          display: flex;

          .card-editor-footer-img {
            margin-right: 32px;
            display: flex;
            align-items: center;

            &:hover {
              cursor: pointer;
            }

            img {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
          }
        }

        .right-save-text {
          font-size: 16px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .card-editor-toggle-icon {
      width: 72px;
      height: 144px;
      background: rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.pre {
        left: 0;
        border-radius: 0 72px 72px 0;

        .pre-img {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }
      }

      &.next {
        right: 0;
        border-radius: 72px 0 0 72px;

        .next-img {
          width: 24px;
          height: 24px;
          margin-left: 32px;
        }
      }
    }
  }
}

::v-deep .el-notification {
  z-index: 9999;
}
</style>

<style>
.ck-rounded-corners .ck.ck-balloon-panel {
  z-index: 99999 !important;
}

.official-classic .ck-editor__editable_inline {
  min-height: 30px;
}

.duplicate-notice {
  background-color: rgba(255, 177, 176, 0.2);
  border-radius: 10px;
}
</style>
