<template>
  <div class="card-list-contain">
    <div class="card-box not-select" :class="{ 'cardPadding': needPadding, 'pd-l-12': needMargin }">
      <div class="card" v-for="(card, index) in list" :key="card.cardId"
        :style="{ marginRight: hideMgRight(index), width: getShowColumn(showColumn) }">
        <div @click.stop="handleCardClick(card, index)" :class="[isActive(card), isFromSearch ? 'fromSearch' : '']"
          class="card-container" :style="{
  background: fetchCardBgc1(card.flags),
}">
          <div class="card-content">
            <div class="card-title" v-html="card.fld1" v-if="card.fld1.trim().length > 0"></div>
            <div class="card-detail" :class="card.image ? 'clip1' : 'clip5'" v-html="card.fld2" v-if="card.fld2"></div>
            <img v-if="card.image" class="card-img shadow-2" :src="card.image" onerror="onImageLoadErr(this)" />
          </div>
          <div class="card-label-container">
            <!-- <div class="card-type">
              <div class="card-type-item" v-if="holdMap[card.status]">
                <svg-icon :iconClass="holdMap[card.status].icon" class="w-14 h-14"></svg-icon>
                <div class="card-type-text">{{ holdMap[card.status].name }}</div>
              </div>
            </div> -->
            <div class="card-chapter-name card-created-time" @click.stop="onCardClick(card)">{{ card.chapterName }}</div>
            <div class="card-created-time" v-if="card.lapses > 3">
              <svg-icon iconClass="forget"></svg-icon>
              忘记{{ card.lapses }}次
            </div>
            <div class="card-created-time" v-else>{{ modifyTime(card.nmod) }}</div>
          </div>
          <div class="edit-box menu-box" @click.stop="editCard(card, index)">
            <svg-icon icon-class="card_edit_menu"></svg-icon>
          </div>
          <div class="flag-box menu-box">
            <div class="flag-icon-box" :style="{ background: fetchCardBgc(card.flags) }">
              <svg-icon icon-class="white_flag" v-if="card.flags !== 0"></svg-icon>
              <svg-icon icon-class="search_flag" v-else></svg-icon>
            </div>
            <div class="flag-menu-container">
              <div class="flag-menu-box">
                <div class="flag-menu-item" @click.stop="flagClick(card, 2)">
                  <div class="item1 item"></div>
                </div>
                <div class="flag-menu-item mg-l-6 mg-r-6" @click.stop="flagClick(card, 1)">
                  <div class="item2 item"></div>
                </div>
                <div class="flag-menu-item mg-r-10" @click.stop="flagClick(card, 3)">
                  <div class="item3 item"></div>
                </div>
                <svg-icon icon-class="card_flag_clear_menu" @click.stop="flagClick(card, 0)"></svg-icon>
              </div>
            </div>
          </div>
          <div class="more-menu-box menu-box" @mouseenter="card.isShowMoreMenu = true"
            @click.stop="onShowMoreMenu(card)" @mouseleave="card.isShowMoreMenu = false">
            <svg-icon icon-class="chapter_more_menu"></svg-icon>
            <!-- <el-collapse-transition> -->
            <transition name="el-zoom-in-bottom">
              <div v-show="card.isShowMoreMenu" @mouseleave="card.isShowMoreMenu = false" class="more-menu-container">
                <div class="more-menu">
                  <div class="more-menu-item" v-for="menu in moreMenuList" :key="menu.id"
                    @click.stop="handleMoreClick(menu, card, index)">
                    <div v-if="menu.id === 3">{{ card.queue === -1 ? '恢复学习' : menu.name }}</div>
                    <div v-else>{{ menu.name }}</div>
                    <svg-icon :iconClass="menu.icon"></svg-icon>
                  </div>
                </div>
              </div>
            </transition>
            <!-- </el-collapse-transition> -->
          </div>
          <div class="card-mod"></div>
        </div>
      </div>
    </div>
    <div class=" mg-b-10 fs-24 text-align-center" v-if="isLoadData"><i class="el-icon-loading"
        :class="page === 1 ? 'my-loading' : ''" style="color:#ffb700"></i></div>
    <BatchNav :selectCardProp="selectCard" :batchSelectedCardProp="batchSelectedCard" :list="list"
      @upDateSelectCard="upDateSelectCard" @openChapterOption="openChapterOption"
      @changeModelSuccess="changeModelSuccess">
    </BatchNav>
  </div>
</template>

<script>
import request from '../libs/api.request'
import { showMessage } from '../libs/message'
import BatchNav from './common/BatchNav.vue'
import dayjs from 'dayjs'
export default {
  props: {
    cardList: {
      type: Array,
      default: () => []
    },
    // 来自搜索界面
    isFromSearch: {
      type: Boolean,
      default: false
    },
    enableBatchOption: {
      type: Boolean,
      default: true
    },
    isLoadData: {
      type: Boolean,
      default: false
    },
    // 一行显示的个数
    showColumn: {
      type: Number,
    },
    myChapterWidth: {
      type: Number,
    },
    //是不是需要两侧padding
    needPadding: {
      type: Boolean,
      default: true
    },
    //用在搜索弹窗里的
    needMargin: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    }
  },
  components: {
    BatchNav
  },
  data() {
    return {
      holdMap: {
        1: { icon: 'new_card', id: 1, name: '新卡' },
        2: { icon: 'studying', id: 2, name: '记忆中' },
        3: { icon: 'handle', id: 3, name: '已掌握' },
        5: { icon: 'no_review', id: 5, name: '不复习' }
      },
      moreMenuList: [
        { name: '批量管理', icon: 'batch_all', id: 1 },
        { name: '停学卡片', icon: 'stop_study', id: 3 },
        { name: '删除卡片', icon: 'delete_card', id: 2 },
      ],
      selectCard: [],
      batchSelectedCard: [],//批量操作选中的卡，如果当前是全选状态则是排除的
      // batchSelectedAll: false,//是否全选
      currentIndex: '',
      dropdownMenuList: [
        {
          title: '重命名',
          id: 1
        },
        {
          title: '停止学习/恢复学习',
          id: 2
        },
        {
          title: '创建卡牌盒',
          id: 3
        },
        {
          title: '删除卡牌盒',
          id: 4
        },
      ],
      list: this.cardList,
      toChapterId: null// 移动到所在目录的chapterId
    }
  },
  methods: {
    changeModelSuccess() {
      this.$emit('changeModelSuccess')
    },
    onCardClick(card) {
      this.$router.push('/home/studyPlatform/chapter/' + card.chapterId)
    },
    upDateSelectCard(batchSelectedCard, selectCard) {
      this.batchSelectedCard = batchSelectedCard
      this.selectCard = selectCard
    },
    handleMoreClick(menu, card, index) {
      if (menu.id === 1) {
        // 批量管理
        card.isShowMoreMenu = false
        this.$store.commit('setSelectAll', true)
      } else if (menu.id === 2) {
        this.deleteCard(card, index)
      } else if (menu.id === 3) {
        //停学卡片//恢复学习
        this.stopStudyCard(card)
      }
    },
    onShowMoreMenu(card) {
      card.isShowMoreMenu = !card.isShowMoreMenu
    },
    onBatchSelectedAllChange(e) {
      if (e) {
        this.batchSelectedCard = this.list.map(v => v.cardId)
        this.selectCard = this.list
      } else {
        this.batchSelectedCard = [];
        this.selectCard = []
      }
    },
    openChapterOption() {
      this.$emit('openChapterOption', this.batchSelectedCard, 'move')
    },
    // 编辑此卡牌
    editCard(card, index) {
      card.isShowMenu = false
      if (card.editable === 0) {
        return showMessage({ message: '此卡牌不能被编辑', type: 'error' })
      }
      this.$myLoading.start({ bgcColor: 'rgba(247, 247, 247, .9)' })
      request.request({
        url: '/card/detail',
        params: {
          cardId: card.cardId
        }
      }).then((res) => {
        this.$myLoading.finishProgress()
        this.$myLoading.finish()
        this.$emit('toEditorCard', res, index)
      }).catch(err => {
        this.$myLoading.finishProgress()
        this.$myLoading.finish()
        showMessage({ message: err.message, type: 'error' })
      })
    },
    // 设置红旗
    flagClick(card, flags) {
      request.request({
        url: '/card/flags',
        method: 'post',
        data: {
          cardId: card.cardId,
          flags
        }
      }).then(res => {
        card.flags = flags;
        card.isShowFlag = false
      }).catch(err => {
        showMessage({ message: err.message, type: 'error' })
      })
    },
    stopStudyCard(card) {
      let title = "";
      let status = ''
      let text = ''
      if (card.queue < 0) {
        title = "确定恢复卡片吗？";
        status = 1
        text = '恢复后，该卡片可以正常学习'
      } else {
        title = "确定停学卡片吗？";
        status = 2
        text = '暂停后，该卡片不会再安排复习，暂停后，可以再恢复'
      }
      this.$confirm(text, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request
              .request({
                url: "/card/suspendSwitch",
                method: "post",
                data: {
                  cardId: card.cardId,
                  status
                },
              })
              .then((data) => {
                card.queue = card.queue === -1 ? card.type : -1
                showMessage({ message: '成功!', type: 'success' })
                done()
              })
              .catch((e) => {
                showMessage({ message: e.message, type: 'error' })
              });
          } else {
            done();
          }
        }
      })
    },
    // 删除卡牌
    deleteCard(card, index) {
      this.$confirm('此操作将删除该卡片, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
      }).then(() => {
        request.request({
          url: '/card/delete',
          data: {
            cardId: card.cardId + ''
          },
          method: 'post'
        }).then(res => {
          this.cardList.splice(index, 1)
          showMessage({ message: '删除成功!', type: 'success' })
        }).catch(err => {
          showMessage({ message: err.message, type: 'error' })
        })
      })
    },
    getCardDetail(card, index) {
      request.request({
        url: "/card/detail",
        params: {
          cardId: card.cardId
        }
      }).then(res => {
        this.$emit('toCardPreview', res, index, this.cardList)
      }).catch(err => {
        showMessage({ message: err.message, type: 'error' })
      })
    },
    // 点击卡牌进入预览
    handleCardClick(card, index) {
      if (this.$store.state.isSelectAll) {
        let indexOf = this.batchSelectedCard.indexOf(card.cardId);
        if (indexOf > -1) {
          this.batchSelectedCard.splice(indexOf, 1);
          this.selectCard.splice(indexOf, 1)
        } else {
          this.batchSelectedCard.push(card.cardId);
          this.selectCard.push(card)
        }
      } else {
        // 获取卡牌详情
        this.getCardDetail(card, index)
      }
    },
    addCardLeft(card, index) {
      this.$emit('openLorRCreateCard', card, index, this.cardList, 'left')
    },
    addCardRight(card, index) {
      this.$emit('openLorRCreateCard', card, index, this.cardList, 'right')
    },

    onStart(e) {
      // console.log('开始拖拽',e);
    },
    onSort(e) {
      this.toChapterId = e.to.dataset.chapterid * 1
    },
    onEnd(e) {
      if (this.toChapterId == null) return // 没有变化，则return
      const card = e.item._underlying_vm_
      if (card.chapterId === this.toChapterId) {
        // 同层级下，索引不变则不执行接口
        if (e.newIndex === e.oldIndex) return
      }
      request.request({
        url: '/card/move',
        method: 'post',
        data: {
          cardId: card.cardId,
          chapterId: this.toChapterId,
          position: e.newIndex + 1
        }
      }).then(res => {
        showMessage({ message: '成功!', type: 'success' })
        this.currentIndex = e.newIndex
      }).catch(e => {
        showMessage({ message: e, type: 'error' })
      })
    }
  },
  computed: {
    hideMgRight() {
      return (index) => {
        return (index + 1) % this.showColumn !== 0 ? '12px' : '0'
      }
    },
    getShowColumn() {
      return (n) => {
        return ((this.myChapterWidth - (n - 1) * 12) / n) + 'px'
        // return (100 / n)+'%'
      }
    },
    modifyTime() {
      return (time) => {
        return dayjs(time).format('MM.DD HH:mm')
      }
    },
    isActive() {
      return (card, index) => {
        if (this.$store.state.isSelectAll) {
          return this.batchSelectedCard.indexOf(card.cardId) > -1 ? 'active' : ''
        } else {
          return this.currentIndex === index ? 'active' : '';
        }
      }
    },
    fetchCardBgc() {
      return (id) => {
        switch (id) {
          case 0:
            return '#fff'
          case 1:
            return '#FF4B3E'
          case 2:
            return '#FFA800'
          case 3:
            return '#1789FC'
        }
      }
    },
    fetchCardBgc1() {
      return (id) => {
        switch (id) {
          case 0:
            return '#fff'
          case 1:
            return 'rgba(255, 75, 62, .16)'
          case 2:
            return 'rgba(255, 214, 10, .16)'
          case 3:
            return 'rgba(23, 137, 252, .16)'
        }
      }
    },
    showOperateText() {
      return () => {
        if (this.selectCard.some(v => v.queue !== -1)) {
          return '停学'
        } else {
          return "恢复"
        }
      }
    }
  },
  watch: {
    cardList: function (nValue) {
      this.list = nValue
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.card-list-contain {
  position: relative;
}

.my-loading {
  position: fixed;
  top: 40%;
}

.select-all-box {
  position: sticky;
  top: 58px;
  background: #f7f7f7;
  z-index: 9;
}

.card-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 6px;
  word-break: break-all;

  &.cardPadding {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card {
    position: relative;
    border-radius: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
    box-sizing: border-box;

    .card-menu-box {
      box-sizing: content-box !important;
      white-space: nowrap;
      background-color: #fff;
      height: 40px;
      display: flex;
      padding: 0 16px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .card-menu {
        box-sizing: content-box !important;
        display: flex;
        width: max-content;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;

        img {
          width: 20px;
          height: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        .left-add {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }
        }

        .right-add {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }
        }

        .my-hover {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;

          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }

    .card-container:hover .card-mod {
      display: block;
    }

    .card-container:hover .edit-box {
      display: block;
    }

    .card-container:hover .flag-box {
      display: block;
    }

    .card-container:hover .more-menu-box {
      display: block;
    }

    .flag-box:hover .flag-menu-container {
      display: block;
    }

    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 12px;
      background-color: #fff;
      position: relative;
      padding: 16px;
      height: 249px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.03);
      transition: all .3s, -webkit-transform .3s;

      &.fromSearch {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.09);
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        z-index: 9 !important;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      }

      .card-mod {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        display: none;
      }

      .menu-box {
        position: absolute;
        width: 34px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        z-index: 99;
        opacity: .97;

        &:hover {
          opacity: 1;
        }
      }

      .edit-box {
        top: 10px;
        left: 10px;
        display: none;
      }

      .flag-menu-container {
        position: absolute;
        top: 0px;
        right: 30px;
        display: none;
        z-index: 99;
        padding-right: 10px;

        .flag-menu-box {
          padding: 0 9px;
          height: 34px;
          display: flex;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: #fff;

          align-items: center;

          .flag-menu-item {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            .item {
              width: 16px;
              height: 16px;
              border-radius: 50%;
            }

            .item1 {

              background: #FFA800 !important;
            }

            .item2 {
              background: #FF4B3E;
            }

            .item3 {
              background: #1789FC;
            }

            &:hover {
              background-color: #ccc;
            }
          }
        }
      }

      .flag-box {
        top: 10px;
        right: 10px;
        display: none;

        .flag-icon-box {
          width: 34px;
          height: 34px;
          text-align: center;
          line-height: 34px;
          border-radius: 8px;
        }
      }

      .more-menu-box {
        bottom: 10px;
        right: 10px;
        display: none;

        .more-menu-container {
          position: absolute;
          bottom: 35px;
          right: 0;
          z-index: 999999;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          overflow: hidden;

          .more-menu {
            background-color: #fff;
            width: 150px;

            .more-menu-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 40px;
              padding: 0 16px;
              font-size: 14;
              color: #202020;

              &:hover {
                background-color: #f7f7f7;
                cursor: pointer;
              }
            }
          }
        }
      }

      .my-placeholder {
        visibility: hidden;
      }

      &.active {
        box-shadow: 0 0 0 2px rgba(255, 183, 0, 0.5);
      }



      .card-content {
        // flex-grow: 1;
        display: flex;
        flex-direction: column;

        .card-title {
          font-size: 15px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 8px;
          @include clamp(2);
        }

        .card-detail {
          font-size: 14px;
          line-height: 24px;
          color: rgba(32, 32, 32, 0.6);

          &.clip1 {
            @include clamp(1)
          }

          &.clip5 {
            @include clamp(6)
          }
        }

        .card-img {
          width: 100%;
          height: 112px;
          border-radius: 6px;
          margin: 5px 0 10px;
          object-fit: cover;
        }
      }

      .card-label-container {
        display: flex;
        justify-content: space-between;
        z-index: 99;

        .card-type {
          display: flex;
          align-items: center;

          .card-type-item {
            display: flex;
            align-items: center;
            justify-content: center;

            .card-type-text {
              color: rgba(32, 32, 32, 0.4);
              font-size: 12px;
              transform: scale(0.9);
              line-height: 14px;
            }
          }
        }

        .card-chapter-name {
          max-width: 60%;
          @include clamp(1);

          &:hover {
            color: #FFA800;
          }
        }

        .card-created-time {
          width: max-content;
          color: #202020;
          font-size: 12px;
          transform: scale(0.9);
          opacity: .6;
        }
      }
    }
  }
}
</style>
