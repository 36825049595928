<template>
  <div class="study-view" :class="nightMode">
    <div class="study-view-box">
      <div class="study-view-content not-select" :style="handleClienSize()" style="max-width:1024px">
        <div class="study-card-head">
          <div class="card-title">
            <div class="title">{{ card.chapterName }}</div>
          </div>
          <div class="card-title">
            <span class="num">{{ currentIndex + 1 }}/{{ cardList.length }}</span>
            <svg-icon iconClass="cardviwer_more_menu" class="img" @mouseenter="isShowMoreMenu = true"
              @click="isShowMoreMenu = !isShowMoreMenu">
            </svg-icon>
          </div>
        </div>
        <shortcut-key v-show="$store.state.showShortcutkey" :fromCardviewer="true"></shortcut-key>
        <!-- 卡牌内容 -->
        <div class="card-container">
          <div class="more-menu" v-show="isShowMoreMenu" @mouseleave="isShowMoreMenu = false">
            <div class="more-menu-item" v-for="menu in moreMenuList" :key="menu.id" @click="handleMenuClick(menu)">
              <svg-icon :iconClass="menu.icon" class="w-16 h-16 mg-r-14 mg-l-16"></svg-icon>
              <div v-if="menu.id === 'stop' && card.queue < 0">恢复学习卡牌</div>
              <div v-else>{{ menu.name }}</div>
              <el-switch @change="switchChange" class="mg-l-20 w-44 h-24" v-if="menu.id === 'toggleMoon'"
                v-model="showMoon" active-color="#FFB700" inactive-color="rgba(120, 120, 128, 0.16)">
              </el-switch>
            </div>
          </div>
          <iframe :srcdoc="srcdoc" id="card-viewer-iframe" frameborder="0"
            style="width: 100%; border: none;border-radius: 12px;overflow: hidden;" :style="iframeHeight"></iframe>
        </div>
        <div class="show-answer" v-if="!model.noQfmt" :style="{ backgroundColor: bgcColor }">
          <div class="show-answer-text" @click="showAnswer">{{ cardStatus === 'qfmt' ? '显示答案' : '显示正面' }}
          </div>
        </div>
        <div @click="onRefClick" v-if="card && card.refCount > 0"
          class="position-absolute b-100 r-25 bg-f bdr-8 shadow-1 pd-l-15 pd-r-15 pd-t-4 pd-b-4 c-9 cursor-pointer">
          {{ card.refCount }}
        </div>
      </div>
      <div class="study-nav not-select" v-show="!isFullScreen" @mouseleave="isShowFlag = false">
        <div class="nav-icon" :style="handleNavSize()">
          <div class="nav-icon-item" v-for="item in navImgList" :key="item.id" @click="navClick(item)">
            <div class="flag-box" v-if="item.id === 'flag' && isShowFlag">
              <div v-for="(flag, index) in flagList" :key="flag.id" @click.stop="onFlagClick(flag.id, index)"
                class="flag-item">
                <svg-icon :iconClass="flag.icon" class="w-16 16"></svg-icon>
                <div class="flag-name" :class="currentFlagIndex == index ? 'active' : ''">{{ flag.name }}
                </div>
                <svg-icon iconClass="tick" class="w-24 h-24" v-if="currentFlagIndex == index"></svg-icon>
              </div>
            </div>
            <svg-icon :iconClass="item.icon" class="nav-img"></svg-icon>
          </div>
        </div>
        <div class="nav-close" @click="ClosePreview">
          <svg-icon iconClass="cardviwer_close" class="w-30 h-30"></svg-icon>
        </div>
        <div class="nav-screen" @click="triggerNavStatus">
          <svg-icon iconClass="cardviwer_fullscreen" class="w-24 h-24"></svg-icon>
        </div>
      </div>
      <div class="full-screen-btn not-select" v-if="isFullScreen" @click="triggerNavStatus">
        <svg-icon iconClass="cardviwer_nofullscreen" class="w-24 h-24"></svg-icon>
      </div>
      <!-- 上一页 -->
      <div class="study-toggle-icon pre not-select" v-if="cardList.length > 0 && currentIndex > 0" @click="showPreCard">
        <svg-icon iconClass="cardviwer_left_arrow" class="w-24 h-24 mg-l-16"></svg-icon>
      </div>
      <!-- 下一页 -->
      <div class="study-toggle-icon next not-select" v-if="cardList.length > 0 && currentIndex < cardList.length - 1"
        @click="showNextCard">
        <svg-icon iconClass="cardviwer_right_arrow" class="w-24 h-24 mg-l-32"></svg-icon>
      </div>
    </div>
    <tag-card-viewer></tag-card-viewer>
    <link-card-viewer ref="linkCardViewer" :mainCardId="card.cardId" @editorLinkCard="editorLinkCard">
    </link-card-viewer>
    <ModelEditor v-if="modelVisible" :modelVisible.sync="modelVisible" @updateModelVisible="updateModelVisible"
      :model="model">
    </ModelEditor>
  </div>
</template>
<script>
import request from "../libs/api.request";
import CardRender from "../libs/CardRender";
import ShortcutKey from "./common/ShortcutKey.vue";
import { showMessage } from "../libs/message";
import LinkCardViewer from "./common/LinkCardViewer";
import TagCardViewer from "./common/TagCardViewer";
import ModelEditor from "./common/model/ModelEditor.vue";

export default {
  name: "CardViewer",
  props: {
    showEditModelMenu: {
      type: Boolean,
      default: true
    },
    // 第一次进来学习的卡牌盒子数据,
    studyCardData: {
      type: Object,
      default: () => {
      },
    },
    // 预览的卡牌组列表
    cardList: {
      type: Array,
      default: () => [],
    },
    // 卡牌索引
    currentCardIndex: {
      type: Number,
      default: 0,
    },
    // 如果编辑卡牌打开了,则预览处的keydown事件不做处理
    isShowEditorCard: {
      type: Boolean,
      default: false
    },
    // 第一张卡牌的模版
    firstCardModel: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    TagCardViewer,
    LinkCardViewer,
    ShortcutKey,
    ModelEditor
  },
  data() {
    return {
      modelVisible: false,
      modelList: [],
      hasShowDialog: false, // 用来判断esc键是否是退出学习，还是关闭弹窗
      myDone: null,
      showMoon: false,
      bgcColor: '',
      currentFlagIndex: "0",
      isShowFlag: false, //显示标记
      isHasDelete: false,
      isShowMoreMenu: false, // 显示更多菜单
      isFullScreen: false,
      cardStatus: "qfmt", //qfmt 正面  afmt 背面
      nightMode: '', // 颜色反转
      moreMenuList: [
        { id: "stop", name: "停学卡牌", icon: 'stop_study' },
        { id: "reset", name: "重置为新卡", icon: 'reset_study_progress' },
        { id: "toggleMoon", name: "夜间模式", icon: 'moon' },
        { id: "delete", name: "删除卡牌", icon: 'delete_card' },
        { id: "edit_model", name: "编辑模版", icon: 'edit' },
      ],
      navImgList: [
        { id: "volume", icon: "cardviwer_video" },
        { id: "flag", icon: "cardviwer_none_flag" },
        { id: "edit", icon: "cardviwer_edit" },
        { id: "eye", icon: "open_eye" },
        { id: "showShortcutKey", icon: "cardviwer_keyboard" },
        { id: "add", icon: "cardviwer_add" },
      ],
      flagList: [
        { id: 0, name: '无标记', icon: 'cardviwer_none_flag' },
        { id: 1, name: '红色', icon: 'red_flag' },
        { id: 2, name: '黄色', icon: 'yellow_flag' },
        { id: 3, name: '蓝色', icon: 'blue_flag' },
      ],
      clienHeight: 0,
      leftAndRightWidth: 152,
      padHeight: 48, //上下padding的和
      navHeight: 56, // 底部nav的高度
      headAndFootHeiht: 130, //72 头部 80 底部按钮
      card: {}, // 当前卡牌盒子的卡牌数据
      model: {},
      screenWidth: "",
      srcdoc: "",
      currentIndex: this.currentCardIndex, // 当前预览的卡牌的索引
      speechConfig: null,
      msTTSPlayer: null,
      qfmtContentText: '',
      studyTime: {}, // 每张卡片的浏览时长，{cardId:time, 1: 1000}
      isNewStartCalculate: true,//是否开启计算新卡的时长,第一次是自动开启，切换正反面的时候，需要关闭，切换上一下张的时候又重新开启
      getCardTime: 0 // 初始时长
    };
  },
  methods: {
    editorLinkCard(card, model) {
      this.$emit('editorLinkCard', card, model)
    },
    switchChange(v) {
      if (v) {
        this.nightMode = 'night-mode';
      } else {
        this.nightMode = ''
      }
      this.rendHtml();
    },
    onRefClick() {
      this.$refs.linkCardViewer.open();
    },
    handleMenuClick(menu) {
      switch (menu.id) {
        case "delete":
          this.hasShowDialog = true
          this.$confirm(`删除卡牌之后将不能恢复该卡牌，请谨慎操作?`, "删除卡牌", {
            confirmButtonText: "删除",
            cancelButtonText: "取消",
            distinguishCancelAndClose: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request
                  .request({
                    url: "/card/delete",
                    method: "post",
                    data: { cardId: this.card.cardId },
                  })
                  .then((data) => {
                    this.isHasDelete = true
                    showMessage({ message: '删除成功!', type: 'success' })
                    this.$emit("deleteCardSuccess", this.currentIndex);
                    this.hasShowDialog = false
                    done()
                    if (this.cardList.length === 0) {
                      // 删除完了
                      this.$emit('deleteCardListEmpty')
                      return
                    }
                    if (this.currentIndex >= this.cardList.length) {
                      this.currentIndex = this.cardList.length - 1
                    }
                    this.getCardDetail()
                  })
                  .catch((e) => {
                    showMessage({ message: e.message, type: 'error' })
                  });
              } else {
                this.hasShowDialog = false
                done()
              }
            }
          })
          break;
        case "stop":
          this.hasShowDialog = true
          let title = "";
          let status = ''
          let text = ''
          if (this.card.queue < 0) {
            title = "确定恢复卡片吗？";
            status = 1
            text = '恢复后，该卡片可以正常学习'
          } else {
            title = "确定停学卡片吗？";
            status = 2
            text = '暂停后，该卡片不会再安排复习，暂停后，可以再恢复'
          }
          this.$confirm(text, title, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            distinguishCancelAndClose: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request
                  .request({
                    url: "/card/suspendSwitch",
                    method: "post",
                    data: {
                      cardId: this.card.cardId,
                      status
                    },
                  })
                  .then((data) => {
                    this.isHasDelete = true
                    showMessage({ message: '成功!', type: 'success' })
                    this.hasShowDialog = false
                    done()
                  })
                  .catch((e) => {
                    showMessage({ message: e, type: 'error' })
                  });
              } else {
                this.hasShowDialog = false
                done();
              }
            }
          })
          break;
        case "reset":
          this.hasShowDialog = true
          this.$confirm(`重置后，会清除该卡片的学习记录，变成一张新卡片`, "确认重置为新卡吗？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            distinguishCancelAndClose: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request
                  .request({
                    url: "/card/renew",
                    method: "post",
                    data: { cardId: this.card.cardId },
                  })
                  .then((data) => {
                    this.isHasDelete = true
                    showMessage({ message: '重置成功!', type: 'success' })
                    this.hasShowDialog = false
                    done()
                  })
                  .catch((e) => {
                    showMessage({ message: e, type: 'error' })
                  });
              } else {
                this.hasShowDialog = false
                done();
              }
            }
          })
          break;
        case "edit_model":
          console.log(this.model);
          if (this.model.recommend && this.model.recommend === 1) {
            return showMessage({ message: '不支持编辑官方/他人模板', type: 'error' })
          }
          if (this.model.creatorId !== this.model.userId) {
            return showMessage({ message: '只允许编辑自己的模版', type: 'error' })
          }
          this.modelVisible = true
          break
      }
    },
    // 上一张
    showPreCard() {
      this.navImgList[3].icon = 'open_eye'
      if (this.currentIndex === 0) {
        showMessage({ message: '当前是第一张', type: 'warning' })
      } else {
        this.currentIndex--;
        this.getCardDetail();
        if (this.msTTSPlayer) {
          try {
            if (this.msTTSPlayer !== true) {
              this.msTTSPlayer.pause();
              this.msTTSPlayer = null;
            }
          } catch (e) {
          }
          return;
        }
      }
    },
    // 下一张
    showNextCard() {
      this.navImgList[3].icon = 'open_eye'
      if (this.currentIndex < this.cardList.length - 1) {
        this.currentIndex++;
        this.getCardDetail();
        if (this.msTTSPlayer) {
          try {
            if (this.msTTSPlayer !== true) {
              this.msTTSPlayer.pause();
              this.msTTSPlayer = null;
            }
          } catch (e) {

          }
          return;
        }
      } else {
        setTimeout(() => {
          if (this.currentIndex == this.cardList.length - 1) {
            showMessage({ message: '当前是最后一张', type: 'warning' })
          }
        }, 100);
      }
    },
    navClick(item) {
      switch (item.id) {
        case "volume":
          console.log("？？？", this.msTTSPlayer);
          if (this.msTTSPlayer) {
            try {
              if (this.msTTSPlayer !== true) {
                this.msTTSPlayer.pause();
                this.msTTSPlayer = null;
              }
            } catch (e) {

            }
            return;
          }
          this.msTTSPlayer = true;

          let div = window.document.getElementById("card-viewer-iframe").contentWindow.document.body.cloneNode(true);
          div.querySelectorAll(".not-voice,script,[hidden],style").forEach(function (element) {
            element.parentNode.removeChild(element);
          });
          let contentText = div.innerText.trim();
          contentText = contentText.replace(new RegExp("\n", "gm"), ' ');
          contentText = contentText.replace(new RegExp("\r", "gm"), ' ');
          do {
            contentText = contentText.replace(new RegExp("   ", "gm"), ' ');
          } while (contentText.indexOf("   ") > 0);
          console.log(contentText);
          if (!contentText) {
            return;
          }

          if (contentText.length > 300) {
            contentText = contentText.substr(0, 300);
          }
          if (this.cardStatus == 'afmt') {
            contentText = this.qfmtContentText ? contentText.replace(this.qfmtContentText, '') : contentText;
          } else {
            this.qfmtContentText = contentText;
          }
          request.request({
            url: '/card/volume',
            method: 'post',
            data: { text: contentText }
          }).then((res) => {
            this.msTTSPlayer = new SpeechSDK.SpeakerAudioDestination();
            this.msTTSPlayer.onAudioEnd = () => {
              this.msTTSPlayer = null;
            }

            if (!this.speechConfig) {
              this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription('5a6de7c8f8c34affb14860576b09fa15', 'japaneast');
            }
            this.speechConfig.speechSynthesisVoiceName = res.voiceName;
            let audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(this.msTTSPlayer);
            this.synthesizer = new SpeechSDK.SpeechSynthesizer(this.speechConfig, audioConfig);
            this.synthesizer.speakTextAsync(
              contentText, (result) => {
                this.synthesizer.close();
                this.synthesizer = null;
              }, (err) => {
                this.synthesizer.close();
                this.synthesizer = null;
              })
          }).catch(e => {
            this.msTTSPlayer = null;
            this.synthesizer = null;
          });
          break;
        case "showShortcutKey":
          if (this.$store.state.showShortcutkey) {
            this.navImgList[4].icon = 'cardviwer_keyboard'
            this.$store.commit('changeShowShortcutkey', false)
          } else {
            this.navImgList[4].icon = 'cardviwer_keyboard_active'
            this.$store.commit('changeShowShortcutkey', true)
          }
          break;
        case "flag":
          this.isShowFlag = !this.isShowFlag;
          break;
        case "edit":
          if (this.card.editable === 0) {
            return showMessage({ message: '此卡牌不能被编辑', type: 'error' })
          }
          console.log(this.card);
          this.$emit("previewToEditorCard", this.card, this.currentIndex, this.model);
          break;
        case "eye":
          let d = window.document.getElementById("card-viewer-iframe").contentWindow;
          if (this.navImgList[3].icon === 'open_eye') {
            if (d.coverSwitch) {
              d.coverSwitch(true)
            }
            this.navImgList[3].icon = 'close_eye'
          } else {
            if (d.coverSwitch) {
              d.coverSwitch(false)
            }
            this.navImgList[3].icon = 'open_eye'
          }
          break
        case "add":
          console.log(this.card);
          this.$emit('openCreateCard', this.card)
          break
      }
    },
    onFlagClick(flags, index) {
      request
        .request({
          url: "/card/flags",
          method: "post",
          data: {
            cardId: this.card.cardId,
            flags,
          },
        })
        .then(() => {
          this.selectFlag(flags);
          this.isShowFlag = false;
          this.currentFlagIndex = index;
          showMessage({ message: '标记成功!', type: 'success' })
        })
        .catch((err) => {
          showMessage({ message: err, type: 'error' })
        });
    },
    // 计算卡片浏览时长
    calculateTime() {
      let time = 0
      time = new Date().getTime() - this.getCardTime
      console.log('time', time);
      this.studyTime[this.card.cardId] += time
      console.log('studyTime', this.studyTime);
    },
    // 卡牌内容渲染
    rendHtml() {
      // 开始计算新的卡牌时长
      if (this.isNewStartCalculate) {
        this.getCardTime = new Date().getTime()
      }
      if (!Object.hasOwnProperty.call(this.studyTime, this.card.cardId)) {
        this.studyTime[this.card.cardId] = 0
      }
      if (this.model.noQfmt) {
        this.cardStatus = 'afmt';
      }
      console.log('model', this.model);
      this.srcdoc = CardRender(this.cardStatus, this.card, this.model, this.nightMode === 'night-mode');
      console.log(this.srcdoc);
      this.$nextTick(() => {
        setTimeout(() => {
          let body = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByTagName('body')[0];
          if (body == undefined) {
            this.bgcColor = 'rgba(255, 255, 255)'
            return
          }
          let color = window.getComputedStyle(body, null).backgroundColor;
          if (color == 'rgba(0, 0, 0, 0)') {
            color = 'rgba(255, 255, 255)'
          }
          this.bgcColor = color
        }, 300)
      })
    },
    getCardDetail() {
      this.calculateTime() // 计算上一张卡片浏览时间
      /////////
      this.isNewStartCalculate = true // 开启新的计时
      this.card = this.cardList[this.currentIndex];
      request
        .request({
          url: "/card/detail",
          params: {
            cardId: this.card.cardId,
          },
        })
        .then(async (res) => {
          this.card = res;
          this.selectFlag(this.card.flags);
          this.currentFlagIndex = this.card.flags;
          this.model = await this.getModelDetail(this.card.modelId);
          this.cardStatus = "qfmt";
          this.rendHtml();
        })
        .catch((err) => {
          showMessage({ message: err, type: 'error' })
        });
    },
    showAnswer() {
      this.calculateTime()
      if (this.cardStatus === 'afmt') {
        this.cardStatus = 'qfmt'
      } else {
        this.cardStatus = 'afmt'
      }
      this.isNewStartCalculate = false
      this.rendHtml();
    },
    triggerNavStatus() {
      this.isFullScreen = !this.isFullScreen;
    },
    ClosePreview() {
      this.calculateTime()
      request.request({
        url: '/card/submitStudyTime',
        method: 'post',
        data: {
          cardList: JSON.stringify(this.studyTime)
        }
      }).then(res => {
        console.log(res);
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
      this.$emit("ClosePreview", this.isHasDelete);
    },
    async getModelDetail(modelId) {
      if (this.modelList != null && this.modelList != undefined) {
        for (let value of this.modelList) {
          if (value.modelId === modelId) {
            return value
          }
        }
      }
      let res = await request.request({
        url: "model/detail",
        params: {
          modelId: modelId
        }
      });
      this.modelList.push(res)
      return res;
    },
    selectFlag(flags) {
      switch (flags) {
        case 0:
          this.navImgList[1].icon = 'cardviwer_none_flag';
          break;
        case 1:
          this.navImgList[1].icon = 'red_flag';

          break;
        case 2:
          this.navImgList[1].icon = 'yellow_flag';

          break;
        case 3:
          this.navImgList[1].icon = 'blue_flag';
          break;
      }
    },
    updateModelVisible(v) {
      this.modelVisible = v
    },
    handleResize() {
      this.screenWidth = window.document.documentElement.clientWidth;
      this.clienHeight = window.document.documentElement.clientHeight;
    },
    handleClienSize() {
      if (this.isFullScreen) {
        let height = this.clienHeight - this.padHeight;
        let width = this.screenWidth * 0.55
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}"`
      } else {
        let height = this.clienHeight - this.padHeight - this.navHeight;
        let width = this.screenWidth * 0.55
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}`
      }
    },
    handleNavSize() {
      if (this.isFullScreen) {
        let width = (this.clienHeight - this.padHeight) * 0.84;
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152;
        }
        return `width:${width}px`;
      } else {
        let width = (this.clienHeight - this.padHeight - this.navHeight) * 0.84;
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152;
        }
        return `width:${width}px`;
      }
    },
    // key up 用来处理没有默认事件的，比如按下数字空格之类的，
    handleKeyDown(e) {
      if (!this.isShowEditorCard) {
        if (e.keyCode === 81 && e.ctrlKey) {
          // ctrl + q 停学卡牌
          this.handleMenuClick({ id: 'stop' })
        } else if (e.keyCode === 82 && e.ctrlKey) {
          // ctrl + r 重置
          this.handleMenuClick({ id: 'reset' })
          e.preventDefault()
        } else if (e.keyCode === 83) {
          console.log('朗读');
        } else if (e.keyCode === 69 && e.ctrlKey) {
          // ctrl + e 编辑
          this.navClick({ id: 'edit' })
          e.preventDefault()
        } else if (e.keyCode === 27 && !this.hasShowDialog) {
          // esc 退出预览
          console.log('tuichuyulan');
          this.ClosePreview()
        }
      }
    },
    // keydon用来处理有ctrl + 有默认事件的
    handleKeyUp(e) {
      console.log(e);
      if (!this.isShowEditorCard) {
        if (e.keyCode === 37) {
          // 键盘左键
          this.showPreCard()
          e.preventDefault()
        } else if (e.keyCode === 39) {
          // 键盘右键
          this.showNextCard()
        } else if (e.keyCode === 32) {
          // 空格
          this.showAnswer()
        } else if (e.keyCode === 48 || e.keyCode === 96) {
          // 无标记
          this.onFlagClick(0, 0)
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          // 标记红色
          this.onFlagClick(1, 1)
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          // 标记橙色
          this.onFlagClick(2, 2)
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          // 标记蓝色
          this.onFlagClick(3, 3)
        } else if (e.keyCode === 8 || e.keyCode === 46) {
          // 删除 delete
          this.handleMenuClick({ id: 'delete' })
        }
      }
    },

  },
  computed: {
    iframeHeight() {
      if (this.isFullScreen) {
        return `height: ${this.clienHeight - this.padHeight
          }px`;
      } else {
        return `height: ${this.clienHeight -
          this.padHeight -
          this.navHeight
          }px`;
      }
    },
    currentStudyProgress() {
      return this.studyNum > 0
        ? parseInt((this.studyNum / (this.studyNum + this.queue)) * 100)
        : 0;
    },
  },
  watch: {
    screenWidth(n) {
      this.screenWidth = n;
      this.handleClienSize();
      this.handleNavSize();
    },
    cardStatus(n, o) {
      if (n === 'qfmt') {
        this.qfmtContentText = '';
      }
    }
  },
  created() {
    console.log('firstMolde', this.firstCardModel);
    if (Object.keys(this.firstCardModel).length > 0) {
      // 将第一张的卡牌模版，加入到模版列表中
      this.model = this.firstCardModel
      this.modelList.push(this.model)
    }
    this.card = this.studyCardData;
    this.currentFlagIndex = this.card.flags;
    this.selectFlag(this.card.flags);
    this.rendHtml();
    if (this.$store.state.showShortcutkey) {
      this.navImgList[4].icon = 'cardviwer_keyboard_active'
    } else {
      this.navImgList[4].icon = 'cardviwer_keyboard'
    }
  },
  mounted() {
    sessionStorage.removeItem('result-time');
    sessionStorage.removeItem('question-statistics');
    window.addEventListener('keyup', this.handleKeyUp, true)
    window.addEventListener('keydown', this.handleKeyDown, true)
    this.clienHeight = window.document.documentElement.clientHeight;
    this.screenWidth = window.document.documentElement.clientWidth;
    document.body.style.cssText = "overflow-y: hidden; height: 100%;";
    window.addEventListener("resize", this.handleResize, true);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize, true);
    window.removeEventListener("keydown", this.handleKeyDown, true);
    window.removeEventListener('keyup', this.handleKeyUp, true)
    if (this.msTTSPlayer) {
      try {
        if (this.msTTSPlayer !== true) {
          this.msTTSPlayer.pause();
          this.msTTSPlayer = null;
        }
      } catch (e) {

      }
      return;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.study-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding-top: 24px;
  padding-bottom: 24px;

  .study-view-box {
    position: relative;
    padding: 0 76px;

    .study-view-content {
      position: relative;
      // max-width: 744px !important;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 24px;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);

      .study-card-head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom: 1px solid rgba(32, 32, 32, 0.0600);

        .card-title {
          display: flex;
          align-items: center;

          .img {
            width: 20px;
            height: 20px;
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }
          }

          .title {
            @include clamp(1);
            font-size: 14px;
            color: rgba(0, 0, 10, 0.6);
            opacity: 0.8;
          }

          .num {
            margin-right: 16px;
            font-size: 16px;
            color: rgba(0, 0, 10, 0.4);
            line-height: 16px;
            opacity: 0.8;
          }
        }
      }

      .card-container {
        padding: 34px 24px -1px 24px;
        position: relative;
        overflow: hidden;

        .more-menu {
          position: absolute;
          top: 40px;
          right: 0;
          z-index: 99;
          width: 192px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          overflow: hidden;

          .more-menu-item {
            display: flex;
            align-items: center;
            height: 48px;
            font-size: 14px;
            color: #202020;

            &:hover {
              cursor: pointer;
              background-color: #fff8e7;
              color: #ffb700;
            }
          }
        }
      }

      .show-answer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 16px;
        color: #00000a;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .show-answer-text {
          &:hover {
            color: #ffb700;
          }
        }

        border-top: 1px solid rgba(32, 32, 32, 0.06);

        &:hover {
          cursor: pointer;
        }
      }
    }

    .study-nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;

      .nav-icon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 744px;

        .nav-icon-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
          }

          .nav-img {
            width: 24px;
            height: 24px;
          }

          .flag-box {
            position: absolute;
            bottom: 56px;
            width: 180px;
            height: 192px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

            .flag-item {
              width: 100%;
              height: 48px;
              display: flex;
              align-items: center;
              padding: 0 12px 0 16px;

              &:hover {
                background: #fff8e7;
              }

              .flag-img {
                width: 16px;
                height: 16px;
              }

              .flag-name {
                color: #202020;
                margin-left: 14px;
                margin-right: auto;

                &.active {
                  font-weight: 600;
                }
              }

              .check-img {
                width: 24px;
                height: 24px;
                text-align: right;
              }
            }
          }
        }
      }

      .nav-close {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .nav-screen {
        position: absolute;
        left: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .full-screen-btn {
      position: absolute;
      bottom: 24px;
      left: 24px;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .full-screen-img {
        width: 24px;
        height: 24px;
      }
    }

    .study-toggle-icon {
      width: 72px;
      height: 144px;
      background: rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      z-index: 99999;

      &:hover {
        cursor: pointer;
      }

      &.pre {
        left: 0;
        border-radius: 0 72px 72px 0;
      }

      &.next {
        right: 0;
        border-radius: 72px 0 0 72px;
      }
    }
  }
}

iframe::-webkit-scrollbar {
  width: 0;
}


.night-mode {
  filter: invert(1);
}

.night-mode img {
  filter: invert(1);
}
</style>
