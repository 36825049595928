<template>
  <div class="study-view" :class="nightMode">
    <div class="study-view-box">
      <div class="study-view-content not-select" :style="handleClienSize()" style="max-width:1024px">
        <div class="study-card-head">
          <div class="card-title">
            <div class="title">{{ card.chapterName }}</div>
          </div>
          <div class="card-title">
            <span class="num">{{ queue }}</span>
            <svg-icon iconClass="cardviwer_more_menu" class="img" @mouseenter="isShowMoreMenu = true"
              @click="isShowMoreMenu = !isShowMoreMenu"></svg-icon>
          </div>
        </div>
        <shortcut-key v-show="$store.state.showShortcutkey" :fromCardviewer="false"></shortcut-key>
        <!-- 卡牌内容 -->
        <div class="card-container not-select">
          <div class="more-menu" v-show="isShowMoreMenu" @mouseleave="isShowMoreMenu = false">
            <div class="more-menu-item" v-for="menu in moreMenuList" :key="menu.id" @click="handleMenuClick(menu)">
              <svg-icon :iconClass="menu.icon" class="w-16 h-16 mg-r-14 mg-l-16"></svg-icon>
              <div v-if="menu.id === 'stop' && card.queue < 0">恢复学习卡牌</div>
              <div v-else>{{ menu.name }}</div>
              <el-switch @change="switchChange" class="mg-l-20 w-44 h-24" v-if="menu.id === 'toggleMoon'"
                v-model="showMoon" active-color="#FFB700" inactive-color="rgba(120, 120, 128, 0.16)"></el-switch>
            </div>
          </div>
          <iframe id="card-viewer-iframe" class="hide-scrollbar" :srcdoc="srcdoc" frameborder="0"
            style="width: 100%;border: none; border-radius: 12px;" :style="iframeHeight">
          </iframe>
        </div>
        <div class="show-answer" :style="{ backgroundColor: bgcColor }">
          <el-progress :percentage="currentStudyProgress" :color="'#FFB700'" :stroke-width="2" :show-text="false">
          </el-progress>
          <div class="show-answer-text" v-if="cardStatus === 'qfmt'" @click="showAnswer">显示答案</div>
          <!-- 学习按钮 -->
          <div class="show-answer-end not-select" v-else>
            <div class="answer-forget" :class="theNumber === 1 ? 'keydownStyle1' : ''" @click="forgetClick"
              v-if="studyBtn.forget !== null"
              :style="{ width: studyBtn.forget !== null && studyBtn.normal !== null && studyBtn.easy !== null ? '32%' : '50%' }">
              <img src="../assets/images/icons_outline_social_light_emoji-hard_24pt@2x.png" class="answer-img" />
              <div class="answer-text">
                <!-- <div class="text">{{card.queue === 2? '忘记': '重来'}}</div> -->
                <div class="text">忘记</div>
                <div class="time">{{ studyBtn.forget.formatIvl }}</div>
              </div>
            </div>
            <div class="answer-forget confuse" :class="theNumber === 2 ? 'keydownStyle2' : ''" @click="normalClick"
              v-if="studyBtn.normal !== null"
              :style="{ width: studyBtn.forget !== null && studyBtn.normal !== null && studyBtn.easy !== null ? '32%' : '50%' }">
              <img src="../assets/images/icons_outline_social_light_emoji-medium_24pt@2x.png"
                class="answer-img confuse-img" />
              <div class="answer-text">
                <div class="text">模糊</div>
                <div class="time">{{ studyBtn.normal.formatIvl }}</div>
              </div>
            </div>
            <div class="answer-forget simple" :class="theNumber === 3 ? 'keydownStyle3' : ''" @click="easyClick"
              v-if="studyBtn.easy !== null"
              :style="{ width: studyBtn.forget !== null && studyBtn.normal !== null && studyBtn.easy !== null ? '32%' : '50%' }">
              <img src="../assets/images/icons_outline_social_light_emoji-easy_24pt@2x.png"
                class="answer-img simple-img" />
              <div class="answer-text">
                <div class="text">简单</div>
                <div class="time">{{ studyBtn.easy.formatIvl }}</div>
              </div>
            </div>
          </div>
        </div>
        <div @click="onRefClick" v-if="card && card.refCount > 0"
          class="position-absolute b-100 r-25 bg-f bdr-8 shadow-1 pd-l-15 pd-r-15 pd-t-5 pd-b-5 c-9 cursor-pointer">
          {{ card.refCount }}
        </div>
      </div>
      <div class="study-nav not-select" v-show="!isFullScreen" @mouseleave="isShowFlag = false">
        <div class="nav-icon" :style="handleNavSize()">
          <div class="nav-icon-item" v-for="item in navImgList" :key="item.id" @click="navClick(item)">
            <div class="flag-box" v-if="item.id === 'flag' && isShowFlag">
              <div v-for="(flag, index) in flagList" :key="flag.id" @click.stop="onFlagClick(flag.id, index)"
                class="flag-item">
                <svg-icon :iconClass="flag.icon" class="w-16 16"></svg-icon>
                <div class="flag-name" :class="currentFlagIndex == index ? 'active' : ''">{{ flag.name }}</div>
                <svg-icon iconClass="tick" class="w-24 h-24" v-if="currentFlagIndex == index"></svg-icon>
              </div>
            </div>
            <svg-icon :iconClass="item.icon" class="nav-img"></svg-icon>
          </div>
        </div>
        <div class="nav-close" @click="exitStudy">
          <svg-icon iconClass="cardviwer_close" class="w-30 h-30"></svg-icon>
        </div>
        <div class="nav-screen" @click="triggerNavStatus">
          <svg-icon iconClass="cardviwer_fullscreen" class="w-24 h-24"></svg-icon>
        </div>
      </div>
      <div class="full-screen-btn" v-if="isFullScreen" @click="triggerNavStatus">
        <svg-icon iconClass="cardviwer_nofullscreen" class="w-24 h-24"></svg-icon>
      </div>
    </div>
    <link-card-viewer ref="linkCardViewer" :mainCardId="card.cardId" @editorLinkCard="editorLinkCard"></link-card-viewer>
    <ModelEditor v-if="modelVisible" :modelVisible.sync="modelVisible" @updateModelVisible="updateModelVisible"
      :model="model">
    </ModelEditor>
  </div>
</template>
<script>
import request from '../libs/api.request'
import CardRender from '../libs/CardRender';
import ShortcutKey from "./common/ShortcutKey.vue";
import { showMessage } from '../libs/message';
import LinkCardViewer from "./common/LinkCardViewer";
import ModelEditor from './common/model/ModelEditor.vue';

export default {
  props: {
    // 第一次进来学习的卡牌盒子数据,
    studyCardData: {
      type: Object,
      default: () => {
      }
    },
    // 如果编辑卡牌打开了,则学习处的keydown事件不做处理
    isShowEditorCard: {
      type: Boolean,
      default: false
    },
    // 第一张卡牌的模版
    firstCardModel: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    LinkCardViewer,
    ShortcutKey,
    ModelEditor
  },
  data() {
    return {
      modelVisible: false,
      theNumber: 0,
      hasShowDialog: false, // 用来判断esc键是否是退出学习，还是关闭弹窗
      showMoon: false,
      bgcColor: '',
      currentFlagIndex: '0',
      isShowFlag: false,//显示标记
      isShowMoreMenu: false, // 显示更多菜单
      isFullScreen: false,
      cardStatus: 'qfmt',//qfmt 正面  afmt 背面
      nightMode: '', // 颜色反转
      moreMenuList: [
        { id: "stop", name: "停学卡牌", icon: 'stop_study' },
        { id: "reset", name: "重置为新卡", icon: 'reset_study_progress' },
        { id: "toggleMoon", name: "夜间模式", icon: 'moon' },
        { id: "delete", name: "删除卡牌", icon: 'delete_card' },
        { id: "edit_model", name: "编辑模版", icon: 'edit' }
      ],
      navImgList: [
        { id: "volume", icon: "cardviwer_video" },
        { id: "flag", icon: "cardviwer_none_flag" },
        { id: "edit", icon: "cardviwer_edit" },
        { id: "undo", icon: "cardviwer_undo" },
        { id: "eye", icon: "open_eye" },
        { id: "showShortcutKey", icon: "cardviwer_keyboard" },
        { id: "add", icon: "cardviwer_add" },
      ],
      flagList: [
        { id: 0, name: '无标记', icon: 'cardviwer_none_flag' },
        { id: 1, name: '红色', icon: 'red_flag' },
        { id: 2, name: '黄色', icon: 'yellow_flag' },
        { id: 3, name: '蓝色', icon: 'blue_flag' },
      ],
      clienHeight: 0,
      leftAndRightWidth: 152,
      padHeight: 48, //上下padding的和
      navHeight: 56, // 底部nav的高度
      headAndFootHeiht: 130, //72 头部 80 底部按钮
      card: {}, // 当前卡牌盒子的卡牌数据
      queue: {},
      model: {},
      screenWidth: "",
      studyBtn: {}, // 当前卡牌的学习按钮
      srcdoc: '',
      studyHasChange: false, // 是否点击了学习的按钮
      time: "", // 答题耗时
      btnType: "", //答题按钮 forget normal easy
      getCardTime: 0,
      studyNum: 0,// 记录点击学习按钮的次数
      previousCard: null,
      modelList: [],
      msTTSPlayer: null
    }
  },
  methods: {
    editorLinkCard(card, model) {
      this.$emit('editorLinkCard', card, model)
    },
    onRefClick() {
      this.$refs.linkCardViewer.open();
    },
    // 控制快捷键的显示与隐藏
    switchChange(v) {
      if (v) {
        this.nightMode = 'night-mode';
      } else {
        this.nightMode = ''
      }
      this.rendHtml();
    },
    // 获取点击学习按钮后处理下一张卡牌的数据
    async getNewStudyCard(res) {
      if (res.card !== null) {
        if (this.card && this.card.cardId) {
          this.previousCard = this.card;
        }
        this.card = res.card;
        await this.getModelDetail()
        this.selectFlag(this.card.flags)
        this.currentFlagIndex = this.card.flags
        this.studyBtn = res.card.btn
        this.queue = res.queue
        this.getCardTime = new Date().getTime()
        this.cardStatus = 'qfmt'
        this.rendHtml()
      } else {
        this.queue = 0
        this.$alert('恭喜！你已经完成了目前的学习任务。', {
          callback: () => {
            location.reload()
          }
        });
      }
    },
    // 卡牌详情页里的
    getNewCard() {
      request.request(
        {
          url: '/chapter/study',
          method: 'post',
          data: {
            studyChapterId: this.$route.params.chapterId || 0,
          }
        }).then(res => {
          this.getNewStudyCard(res)
        })
    },
    handleMenuClick(menu) {
      switch (menu.id) {
        case 'delete':
          this.hasShowDialog = true
          this.$confirm(`删除卡牌之后将不能恢复该卡牌，请谨慎操作?`, '删除卡牌', {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request.request({
                  url: '/card/delete',
                  method: 'post',
                  data: { cardId: this.card.cardId }
                }).then((data) => {
                  showMessage({ message: '删除成功！', type: 'success' })
                  this.hasShowDialog = false
                  done();
                  this.getNewCard()
                  // this.chapterList.splice(index, 1);
                }).catch((e) => {
                  showMessage({ message: e, type: 'error' })
                })
              } else {
                this.hasShowDialog = false
                done();
              }
            }
          })
          break
        case 'stop':
          this.hasShowDialog = true
          this.$confirm(`暂停后，该卡片不会再安排复习，暂停后，可以再恢复`, '确定停学卡片吗?', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request.request({
                  url: '/card/suspendSwitch',
                  method: 'post',
                  data: { cardId: this.card.cardId, status: this.card.queue < 0 ? 1 : 2 }
                }).then((data) => {
                  showMessage({ message: '停学卡片成功！', type: 'success' })
                  this.getNewCard()
                  this.hasShowDialog = false
                  done();
                }).catch((e) => {
                  showMessage({ message: e, type: 'error' })
                })
              } else {
                this.hasShowDialog = false
                done();
              }
            }
          })
          break;
        case 'reset':
          this.hasShowDialog = true
          this.$confirm(`重置后，会清除该卡片的学习记录，变成一张新卡片`, '确认重置为新卡吗？', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request.request({
                  url: '/card/renew',
                  method: 'post',
                  data: { cardId: this.card.cardId }
                }).then((data) => {
                  showMessage({ message: '重置卡片成功！', type: 'success' })
                  this.getNewCard()
                  this.hasShowDialog = false
                  done();
                }).catch((e) => {
                  showMessage({ message: e, type: 'error' })
                })
              } else {
                this.hasShowDialog = false
                done();
              }
            }
          })
          break
        case "edit_model":
          if (this.model.recommend && this.model.recommend === 1) {
            return showMessage({ message: '不支持编辑官方/他人模板', type: 'error' })
          }
          if (this.model.creatorId !== this.model.userId) {
            return showMessage({ message: '只允许编辑自己的模版', type: 'error' })
          }
          this.modelVisible = true
          break
      }
    },
    navClick(item) {
      switch (item.id) {
        case 'volume':
          console.log("？？？", this.msTTSPlayer);
          if (this.msTTSPlayer) {
            try {
              if (this.msTTSPlayer !== true) {
                this.msTTSPlayer.pause();
                this.msTTSPlayer = null;
              }
            } catch (e) {

            }
            return;
          }
          this.msTTSPlayer = true;

          let div = window.document.getElementById("card-viewer-iframe").contentWindow.document.body.cloneNode(true);
          div.querySelectorAll(".not-voice,script,[hidden],style").forEach(function (element) {
            element.parentNode.removeChild(element);
          });
          let contentText = div.innerText.trim();
          contentText = contentText.replace(new RegExp("\n", "gm"), ' ');
          contentText = contentText.replace(new RegExp("\r", "gm"), ' ');
          do {
            contentText = contentText.replace(new RegExp("   ", "gm"), ' ');
          } while (contentText.indexOf("   ") > 0);
          console.log(contentText);
          if (!contentText) {
            return;
          }

          if (contentText.length > 300) {
            contentText = contentText.substr(0, 300);
          }
          if (this.cardStatus == 'afmt') {
            contentText = this.qfmtContentText ? contentText.replace(this.qfmtContentText, '') : contentText;
          } else {
            this.qfmtContentText = contentText;
          }
          request.request({
            url: '/card/volume',
            method: 'post',
            data: { text: contentText }
          }).then((res) => {
            this.msTTSPlayer = new SpeechSDK.SpeakerAudioDestination();
            this.msTTSPlayer.onAudioEnd = () => {
              this.msTTSPlayer = null;
            }

            if (!this.speechConfig) {
              this.speechConfig = SpeechSDK.SpeechConfig.fromSubscription('5a6de7c8f8c34affb14860576b09fa15', 'japaneast');
            }
            this.speechConfig.speechSynthesisVoiceName = res.voiceName;
            let audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(this.msTTSPlayer);
            this.synthesizer = new SpeechSDK.SpeechSynthesizer(this.speechConfig, audioConfig);
            this.synthesizer.speakTextAsync(
              contentText, (result) => {
                this.synthesizer.close();
                this.synthesizer = null;
              }, (err) => {
                this.synthesizer.close();
                this.synthesizer = null;
              })
          }).catch(e => {
            this.msTTSPlayer = null;
            this.synthesizer = null;
          });
          break;
        case 'showShortcutKey':
          if (this.$store.state.showShortcutkey) {
            this.navImgList[5].icon = 'cardviwer_keyboard'
            this.$store.commit('changeShowShortcutkey', false)
          } else {
            this.navImgList[5].icon = 'cardviwer_keyboard_active'
            this.$store.commit('changeShowShortcutkey', true)
          }
          break;
        case 'flag':
          this.isShowFlag = !this.isShowFlag
          break;
        case 'edit':
          if (this.card.editable === 0) {
            return showMessage({ message: '此卡牌不能被编辑', type: 'error' })
          }
          this.$emit('editorCard', this.card, this.model);
          break;
        case "eye":
          let d = window.document.getElementById("card-viewer-iframe").contentWindow;
          console.log(d);
          if (this.navImgList[4].icon === 'open_eye') {
            if (d.coverSwitch) {
              d.coverSwitch(true)
            }
            this.navImgList[4].icon = 'close_eye'
          } else {
            if (d.coverSwitch) {
              d.coverSwitch(false)
            }
            this.navImgList[4].icon = 'open_eye'
          }
          break
        case 'undo':
          if (!this.previousCard) return
          let card = this.previousCard;
          this.previousCard = null;//赋值为空，避免前端频繁点击
          request.request({
            url: '/chapter/undoAnswer',
            method: 'post',
            data: {
              studyChapterId: this.$route.params.chapterId || 0,
              cardId: card.cardId,
            }
          }).then(async res => {
            showMessage({ message: '撤销成功', type: 'success' })
            this.card = res.card
            await this.getModelDetail()
            this.studyBtn = res.card.btn
            this.queue = res.queue
            this.getCardTime = new Date().getTime()
            this.cardStatus = 'qfmt'
            this.rendHtml()
          }).catch(err => {
            this.previousCard = card;
            showMessage({ message: err.message, type: 'error' })
          });
          break
        case 'add':
          // 新建卡牌
          this.$emit('openCreateCard', this.card)
          // this.$emit('openCreateCard', { flds: ['', ''] })
          break;
      }
    },
    onFlagClick(flags, index) {
      request.request({
        url: '/card/flags',
        method: 'post',
        data: {
          cardId: this.card.cardId,
          flags
        }
      }).then(() => {
        this.selectFlag(flags)
        this.isShowFlag = false
        this.currentFlagIndex = index
        showMessage({ message: '标记成功', type: 'success' })
      }).catch(err => {
        showMessage({ message: err, type: 'error' })
      })
    },
    // 卡牌内容渲染
    rendHtml() {
      console.log('render', this.card, this.model);
      if (this.model.noQfmt) {
        this.cardStatus = 'afmt';
      }
      this.theNumber = 0
      if (this.getCardTime === 0) {
        this.getCardTime = new Date().getTime()
      }
      // console.log(this.card);
      this.srcdoc = CardRender(this.cardStatus, this.card, this.model, this.nightMode === 'night-mode');
      this.$nextTick(() => {
        setTimeout(() => {
          let body = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByTagName('body')[0];
          if (body == undefined) {
            this.bgcColor = 'rgba(255, 255, 255)'
            return
          }
          let color = window.getComputedStyle(body, null).backgroundColor;
          if (color == 'rgba(0, 0, 0, 0)') {
            color = 'rgba(255, 255, 255)'
          }
          this.bgcColor = color
        }, 200)
      })
    },
    showAnswer() {
      if (this.cardStatus === 'afmt') {
        this.cardStatus = 'qfmt'
      } else {
        this.cardStatus = 'afmt'
      }
      this.rendHtml();
    },
    studyBtnClick() {
      this.studyNum++
      if (!this.studyHasChange) {
        this.studyHasChange = true
      }

      if (this.card.cardId && this.getCardTime < 100000) {
        showMessage({ message: '出现了点问题', type: 'error' })
        return;
      }
      request.request({
        url: '/chapter/study',
        method: 'post',
        data: {
          studyChapterId: this.$route.params.chapterId || 0,
          cardId: this.card.cardId,
          btnType: this.btnType,
          time: this.card.cardId ? new Date().getTime() - this.getCardTime : 0
        }
      }).then(res => {
        this.getNewStudyCard(res)
        if (this.msTTSPlayer) {
          try {
            if (this.msTTSPlayer !== true) {
              this.msTTSPlayer.pause();
              this.msTTSPlayer = null;
            }
          } catch (e) {

          }
          return;
        }
        this.navImgList[4].icon = 'open_eye'
      }).catch(err => {
        showMessage({ message: err, type: 'error' })
      })
    },
    forgetClick() {
      this.btnType = 'forget'
      this.studyBtnClick()
    },
    normalClick() {
      this.btnType = 'normal'
      this.studyBtnClick()
    },
    easyClick() {
      this.btnType = 'easy'
      this.studyBtnClick()
    },
    triggerNavStatus() {
      this.isFullScreen = !this.isFullScreen
    },
    async getModelDetail() {
      if (this.modelList != null && this.modelList != undefined) {
        for (let value of this.modelList) {
          if (value.modelId === this.card.modelId) {
            this.model = value
            return
          }
        }
      }
      let res = await request.request({
        url: "model/detail",
        params: {
          modelId: this.card.modelId
        }
      });
      this.model = res
      this.modelList.push(res)
    },
    exitStudy() {
      this.hasShowDialog = true
      this.$confirm(`确定退出学习吗?`, {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$emit('exitStudy', this.studyHasChange)
            this.hasShowDialog = false
            done()
          } else {
            this.hasShowDialog = false
            done();
          }
        }
      })
    },
    selectFlag(flags) {
      switch (flags) {
        case 0:
          this.navImgList[1].icon = 'cardviwer_none_flag';
          break;
        case 1:
          this.navImgList[1].icon = 'red_flag';

          break;
        case 2:
          this.navImgList[1].icon = 'yellow_flag';

          break;
        case 3:
          this.navImgList[1].icon = 'blue_flag';
          break;
      }
    },
    handleResize() {
      this.screenWidth = window.document.documentElement.clientWidth
      this.clienHeight = window.document.documentElement.clientHeight
    },
    handleClienSize() {
      if (this.isFullScreen) {
        let height = this.clienHeight - this.padHeight
        let width = this.screenWidth * 0.55

        // let
        // let width = (this.clienHeight - this.padHeight) * 0.84
        // if (width > this.screenWidth - 152) {
        //   width = this.screenWidth - 152
        // }
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}"`
      } else {
        let height = this.clienHeight - this.padHeight - this.navHeight
        let width = this.screenWidth * 0.55
        // let width = (this.clienHeight - this.padHeight - this.navHeight) * 0.84
        // if (width > this.screenWidth - 152) {
        //   width = this.screenWidth - 152
        // }
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}`
      }
    },
    handleNavSize() {
      if (this.isFullScreen) {
        let width = (this.clienHeight - this.padHeight) * 0.84
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152
        }
        return `width:${width}px`
      } else {
        let width = (this.clienHeight - this.padHeight - this.navHeight) * 0.84
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152
        }
        return `width:${width}px`
      }
    },
    handleKeyDown(e) {
      if (!this.isShowEditorCard) {
        if (e.keyCode === 49 || e.keyCode === 97) {
          // 按下1 忘记
          if (this.cardStatus === 'afmt') {
            this.theNumber = 1
          }
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          // 按下2 三个按钮时是模糊
          if (this.cardStatus === 'afmt') {
            if (this.studyBtn.normal !== null && this.studyBtn.easy !== null) {
              this.theNumber = 2
              return
            }
            if (this.studyBtn.normal !== null) {
              this.theNumber = 2
            } else {
              this.theNumber = 3
            }
          }
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          // 按下3 简单
          if (this.cardStatus === 'afmt') {
            // 三个按钮的时候，按3是简单
            if (this.studyBtn.normal !== null && this.studyBtn.easy !== null) {
              this.theNumber = 3
              return
            }
            if (this.studyBtn.normal !== null) {
              this.theNumber = 2
            } else {
              this.theNumber = 3
            }
          }
        } else if (e.keyCode === 81 && e.ctrlKey) {
          // ctrl + q 停学卡牌
          this.handleMenuClick({ id: 'stop' })
        } else if (e.keyCode === 82 && e.ctrlKey) {
          // ctrl + r 重置
          this.handleMenuClick({ id: 'reset' })
          e.preventDefault()
        } else if (e.keyCode === 83) {
          console.log('朗读');
        } else if (e.keyCode === 69 && e.ctrlKey) {
          // ctrl + e 编辑
          this.navClick({ id: 'edit' })
          e.preventDefault()
        } else if (e.keyCode === 90 && e.ctrlKey) {
          // ctrl + z 撤销
          this.navClick({ id: 'undo' })
        } else if (e.keyCode === 27 && !this.hasShowDialog) {
          // esc 退出学习
          this.exitStudy()
          e.preventDefault()
        }
      }
    },
    handleKeyUp(e) {
      if (!this.isShowEditorCard) {
        if (e.keyCode === 32) {
          // 空格
          this.showAnswer()
          e.preventDefault()
          return false
        } else if (e.keyCode === 49 || e.keyCode === 97) {
          // 按下1 忘记
          if (this.cardStatus === 'afmt') {
            this.forgetClick()
          }
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          // 按下2 三个按钮时是模糊
          if (this.cardStatus === 'afmt') {
            if (this.studyBtn.normal !== null && this.studyBtn.easy !== null) {
              this.normalClick()
              return
            }
            if (this.studyBtn.normal !== null) {
              this.normalClick()
            } else {
              this.easyClick()
            }
          }
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          // 按下3 简单
          if (this.cardStatus === 'afmt') {
            // 三个按钮的时候，按3是简单
            if (this.studyBtn.normal !== null && this.studyBtn.easy !== null) {
              this.easyClick()
              return
            }
            if (this.studyBtn.normal !== null) {
              this.normalClick()
            } else {
              this.easyClick()
            }
          }
        } else if (e.keyCode === 48 || e.keyCode === 96) {
          // 无标记
          this.onFlagClick(0, 0)
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          // 标记红色
          this.onFlagClick(1, 1)
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          // 标记橙色
          this.onFlagClick(2, 2)
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          // 标记蓝色
          this.onFlagClick(3, 3)
        } else if (e.keyCode === 8 || e.keyCode === 46) {
          // 删除 delete
          this.handleMenuClick({ id: 'delete' })
        }
        // e.preventDefault()
      }
    },
    updateModelVisible(v) {
      this.modelVisible = v
    }
  },
  computed: {
    iframeHeight() {
      if (this.isFullScreen) {
        return `height: ${this.clienHeight - this.padHeight
          }px`;
      } else {
        return `height: ${this.clienHeight -
          this.padHeight -
          this.navHeight
          }px`;
      }
    },
    currentStudyProgress() {
      return this.studyNum > 0 ? (parseInt(this.studyNum / (this.studyNum + this.queue) * 100)) : 0
    },
  },
  watch: {
    screenWidth(n) {
      this.screenWidth = n
      this.handleClienSize()
      this.handleNavSize()
    }
  },
  created() {
    if (this.studyCardData.card) {
      this.card = this.studyCardData.card
      this.studyBtn = this.studyCardData.card.btn
      this.queue = this.studyCardData.queue
      this.currentFlagIndex = this.card.flags
    }
    if (Object.keys(this.firstCardModel).length > 0) {
      // 将第一张的卡牌模版，加入到模版列表中
      this.model = this.firstCardModel
      this.modelList.push(this.model)
    }
    this.selectFlag(this.card.flags)
    this.rendHtml()
    if (this.$store.state.showShortcutkey) {
      this.navImgList[5].icon = 'cardviwer_keyboard_active'
    } else {
      this.navImgList[5].icon = 'cardviwer_keyboard'
    }
  },
  mounted() {
    sessionStorage.removeItem('result-time');
    sessionStorage.removeItem('question-statistics');
    this.clienHeight = window.document.documentElement.clientHeight
    this.screenWidth = window.document.documentElement.clientWidth
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
    window.addEventListener('resize', this.handleResize, true)
    window.addEventListener('keydown', this.handleKeyDown, true)
    window.addEventListener('keyup', this.handleKeyUp, true)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize, true)
    window.removeEventListener("keydown", this.handleKeyDown, true);
    window.removeEventListener('keyup', this.handleKeyUp, true)
    if (this.msTTSPlayer) {
      try {
        if (this.msTTSPlayer !== true) {
          this.msTTSPlayer.pause();
          this.msTTSPlayer = null;
        }
      } catch (e) {

      }
      return;
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.study-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding-top: 24px;
  padding-bottom: 24px;

  .study-view-box {
    position: relative;
    padding: 0 76px;

    .study-view-content {
      position: relative;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 24px;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);

      .study-card-head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        border-bottom: 1px solid rgba(32, 32, 32, 0.0600);

        .card-title {
          display: flex;
          align-items: center;

          .img {
            width: 20px;
            height: 20px;
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }
          }

          .title {
            @include clamp(1);
            font-size: 14px;
            color: rgba(0, 0, 10, 0.6);
            opacity: 0.8;
          }

          .num {
            margin-right: 16px;
            font-size: 16px;
            color: rgba(0, 0, 10, 0.4);
            line-height: 16px;
            opacity: 0.8;
          }
        }
      }

      .card-container {
        padding: 34px 24px -1px 24px;
        position: relative;

        .more-menu {
          position: absolute;
          top: 40px;
          z-index: 99;
          right: 0;
          width: 192px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          overflow: hidden;

          .more-menu-item {
            display: flex;
            align-items: center;
            height: 48px;
            font-size: 14px;
            color: #202020;

            &:hover {
              cursor: pointer;
              background-color: #fff8e7;
              color: #ffb700;
            }
          }
        }
      }

      .show-answer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 16px;
        color: #00000a;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .show-answer-text {
          &:hover {
            color: #ffb700;
          }
        }

        border-top: 1px solid rgba(32, 32, 32, 0.06);

        &:hover {
          cursor: pointer;
        }

        &.show-top-line {
          border-top: 1px solid rgba(32, 32, 32, 0.06);
        }

        .show-answer-end {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 24px;

          .answer-forget {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 48px;
            background: rgba(120, 120, 128, 0.06);
            border-radius: 6px;

            &:hover {
              cursor: pointer;
              border: 2px solid #ff4b3e;
            }

            .answer-img {
              width: 36px;
              height: 36px;
              background-color: #ff4b3e;
              border-radius: 8px;
              margin-right: 16px;
            }

            .answer-text {
              text-align: left;

              .text {
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: #202020;
              }

              .time {
                margin-top: 8px;
                line-height: 12px;
                color: rgba(32, 32, 32, 0.4);
                font-size: 12px;
              }
            }
          }

          .keydownStyle1 {
            border: 2px solid #ff4b3e;
          }

          .keydownStyle2 {
            border: 2px solid #ff8800;
          }

          .keydownStyle3 {
            border: 2px solid #1789fc;
          }

          .confuse {
            margin: 0 24px;

            &:hover {
              border: 2px solid #ff8800;
            }

            .confuse-img {
              background: #ff8800;
            }
          }

          .simple {
            &:hover {
              border: 2px solid #1789fc;
            }

            .simple-img {
              background: #1789fc;
            }
          }
        }
      }
    }

    .study-nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;

      .nav-icon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 744px;

        .nav-icon-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
          }

          .nav-img {
            width: 24px;
            height: 24px;
          }

          .flag-box {
            position: absolute;
            bottom: 56px;
            width: 180px;
            height: 192px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

            .flag-item {
              width: 100%;
              height: 48px;
              display: flex;
              align-items: center;
              padding: 0 12px 0 16px;

              &:hover {
                background: #fff8e7;
              }

              .flag-img {
                width: 16px;
                height: 16px;
              }

              .flag-name {
                color: #202020;
                margin-left: 14px;
                margin-right: auto;

                &.active {
                  font-weight: 600;
                }
              }

              .check-img {
                width: 24px;
                height: 24px;
                text-align: right;
              }
            }
          }
        }
      }

      .nav-close {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .nav-screen {
        position: absolute;
        left: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .full-screen-btn {
      position: absolute;
      bottom: 24px;
      left: 24px;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .full-screen-img {
        width: 24px;
        height: 24px;
      }
    }

    .study-toggle-icon {
      width: 72px;
      height: 144px;
      background: rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.pre {
        left: 0;
        border-radius: 0 72px 72px 0;

        .pre-img {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }
      }

      &.next {
        right: 0;
        border-radius: 72px 0 0 72px;

        .next-img {
          width: 24px;
          height: 24px;
          margin-left: 32px;
        }
      }
    }
  }
}

.el-progress {
  position: absolute;
  bottom: 80px;
  width: 100%;
  height: 2px;
}

::v-deep .el-progress-bar__outer {
  background: transparent;
  // background: rgba(255, 255, 255, 0.7);
}

.night-mode {
  filter: invert(1);
}

.night-mode img {
  filter: invert(1);
}
</style>
