import { Loading } from "element-ui"
import { showMessage } from "./message"
import request from './api.request'
import store from "../store"
// _this:当前Vue实例， title 标题，text 操作名称， htmlNode dom实例，chapter 数据，url 请求地址
export const handleBatchCard = (_this,title, text, htmlNode, chapter, url) => {
  _this.$confirm(title,'提示', {
    confirmButtonText: text,
    cancelButtonText: '取消',
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        if (htmlNode) { 
          htmlNode.close()
        }
        done();
        let loading = Loading.service({
          lock: true,
          text: `批量${text}中`,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        request.request({
          url,
          method: 'post',
          data: {
            ...chapter
          },
        }).then((res) => {
          store.commit('setSelectAll', false)
          showMessage({message:`批量${text}成功`, type:"success"});
          loading.close();
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }).catch((e) => {
          loading.close();
          showMessage({message: e.message, type: 'error'})
        })
      } else {
        done();
      }
    }
  })
}
