<template>
  <div class="batch-nav-box" v-if="$store.state.isSelectAll">
    <div class="display-flex justify-content-space-between align-items-center ">
      <el-checkbox :checked="batchSelectedCard.length == list.length" @change="onBatchSelectedAllChange">
        已选 {{batchSelectedCard.length }}
      </el-checkbox>
      <div class="display-flex position-fixed r-24 f-14">
        <div class="c-app-yellow mg-r-30 cursor-pointer fs-14" @click="onBatchChangeModel">更换模版</div>
        <div class="c-app-yellow mg-r-30 cursor-pointer fs-14" @click="onBatchMoveClick">移动</div>
        <div class="c-app-yellow mg-r-30 cursor-pointer fs-14" @click="onBatchSuspendClick">{{ showOperateText() }}
        </div>
        <div class="c-app-yellow mg-r-30 cursor-pointer fs-14" @click="onBatchDeleteClick">删除</div>
        <div @click="cancelBatchOption" class="cursor-pointer fs-14 cancel-text">取消</div>
      </div>
    </div>
    <ModelChange :modelChangeVisible="modelChangeVisible" v-if="modelChangeVisible"
      @updateModelChangeVisible="updateModelChangeVisible" :selectModelIdList="selectModelIdList"
      :batchSelectedCard="batchSelectedCard" @changeModelSuccess="changeModelSuccess">
    </ModelChange>
  </div>
</template>

<script>
import { handleBatchCard } from "../../libs/batch"
import { showMessage } from "../../libs/message"
import ModelChange from "./model/ModelChange.vue"
export default ({
  props: {
    // 卡牌列表
    list: {
      type: Array,
      default: () => []
    },
    selectCardProp: {
      type: Array,
      default: () => []
    },
    batchSelectedCardProp: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ModelChange
  },
  data() {
    return {
      batchSelectedCard: this.batchSelectedCardProp, // 批量选中的卡
      selectCard: this.selectCardProp,
      modelChangeVisible: false,
      selectModelIdList: [],
    }
  },
  methods: {
    changeModelSuccess() {
      this.$emit('changeModelSuccess')
    },
    updateModelChangeVisible(v) {
      this.modelChangeVisible = v
    },
    onBatchSelectedAllChange(e) {
      if (e) {
        this.batchSelectedCard = this.list.map(v => v.cardId)
        this.selectCard = this.list
      } else {
        this.batchSelectedCard = [];
        this.selectCard = []
      }
      this.$emit('upDateSelectCard', this.batchSelectedCard, this.selectCard)
    },
    onBatchChangeModel() {
      if (this.batchSelectedCard.length == 0) {
        return showMessage({ message: '请先选择卡片', type: 'warning' })
      }
      this.modelChangeVisible = true
    },
    onBatchMoveClick() {
      if (this.batchSelectedCard.length == 0) {
        return showMessage({ message: '请先选择卡片', type: 'warning' })
      }
      this.$emit('openChapterOption', this.batchSelectedCard, 'move')
    },
    onBatchSuspendClick() {
      if (this.batchSelectedCard.length == 0) {
        return showMessage({ message: '请先选择卡片', type: 'warning' })
      }
      if (this.showOperateText() === '停学') {
        handleBatchCard(this, '是否停学所选卡片?', '停学', null, { cardIds: this.batchSelectedCard, status: 2 }, '/card/batchSuspendSwitch')
      } else {
        handleBatchCard(this, '是否恢复学习所选卡片?', '恢复', null, { cardIds: this.batchSelectedCard, status: 1 }, '/card/batchSuspendSwitch')
      }
    },
    onBatchDeleteClick() {
      if (this.batchSelectedCard.length == 0) {
        return showMessage({ message: '请先选择卡片', type: 'warning' })
      }
      handleBatchCard(this, '是否删除所选卡片?', '删除', null, { cardIds: this.batchSelectedCard }, '/card/batchDelete')
    },
    cancelBatchOption() {
      this.$store.commit('setSelectAll', false)
      // this.batchSelectedAll = false;
      this.batchSelectedCard = [];
      this.selectCard = []
      this.$emit('upDateSelectCard', this.batchSelectedCard, this.selectCard)
    },
  },
  computed: {
    showOperateText() {
      return () => {
        if (this.selectCard.some(v => v.queue !== -1)) {
          return '停学'
        } else {
          return "恢复"
        }
      }
    }
  },
  watch: {
    selectCard(v) {
      this.selectModelIdList = Array.from(new Set(v.map(e => {
        return e.modelId
      })))
    }
  }
})
</script>

<style lang="scss" scoped>
.batch-nav-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  background-color: #f7f7f7;
  padding: 0 24px;
  z-index: 99;
  line-height: 54px;

  .cancel-text {
    color: #000000;
  }
}

::v-deep .el-checkbox__inner {
  border-radius: 50% !important;
}
</style>