<template >
  <div class="study-shortcutKey-box">
    <div class="shortcutKey-item" v-for="(shortcutKeyItem, index) in shortcutKeyList" :key="index">
      <div class="item-key-box">
        <div class="item-key">{{ shortcutKeyItem.key }}</div>
      </div>
      <div class="item-keyname">{{ shortcutKeyItem.value }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fromCardviewer: {
      type: Boolean,
    }
  },
  data() {
    return {
      shortcutKeyList:[],
      shortcutKeyList1: [
        { key: '空格', value: '显示答案/正面' },
        { key: '1', value: '忘记' },
        { key: '2', value: '模糊' },
        { key: '3', value: '简单' },
        { key: 'Ctrl+E', value: '编辑' },
        { key: 'Ctrl+Z', value: '撤销' },
        { key: '0', value: '无标记' },
        { key: '7', value: '标记红色' },
        { key: '8', value: '标记黄色' },
        { key: '9', value: '标记蓝色' },
        // { key: 's', value: '朗读' },
        { key: 'Delete', value: '删除卡片' },
        { key: 'Ctrl+Q', value: '停学卡牌' },
        { key: 'Ctrl+R', value: '重置为新卡' },
        { key: 'Esc', value: '退出学习' },
      ],
      shortcutKeyList2: [
        { key: '空格', value: '显示答案/正面' },
        { key: 'Ctrl+E', value: '编辑' },
        { key: '0', value: '无标记' },
        { key: '7', value: '标记红色' },
        { key: '8', value: '标记橙色' },
        { key: '9', value: '标记蓝色' },
        // { key: 's', value: '朗读' },
        { key: 'Delete', value: '删除卡片'},
        { key: 'Ctrl+Q', value: '停学卡牌' },
        { key: 'Ctrl+R', value: '重置为新卡' },
        { key: 'Esc', value: '退出预览' },
        { key: '←', value: '左切卡片' },
        { key: '→', value: '右切卡片' },
      ],
    }
  },
  created() {
    if(this.fromCardviewer) {
      this.shortcutKeyList = this.shortcutKeyList2
    }else {
      this.shortcutKeyList = this.shortcutKeyList1
    }
  }
}
</script>

<style lang="scss" scoped>
.study-shortcutKey-box {
  position: absolute;
  right: -224px;
  bottom: 12px;
  z-index: 9999;
  .shortcutKey-item {
    display: flex;
    align-items: center;
    margin-top: 13px;
    .item-key-box {
      background: #ffffff;
      box-shadow: 0px 2px 0px 0px rgba(32, 32, 32, 0.16);
      border-radius: 6px;
      height: 24px;
      padding: 0 10px;
      line-height: 24px;
      text-align: center;
      margin-right: 8px;
      .item-key {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.6);
      }
    }
    .item-keyname {
      font-size: 12px;
      color: rgba(32, 32, 32, 0.6);
    }
  }
}
</style>