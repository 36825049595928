<template>
  <div>
    <el-dialog :visible.sync="_modelChangeVisible" append-to-body width="508px" :close-on-click-modal="false"
      title="更换模版" custom-class="model-dialog">
      <div class="model-change_box">
        <div class="title">请选择更换后的模版</div>
        <div class="select-model" @click="onOpenSelectModel">
          <svg-icon iconClass="card_template" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10" v-if="model.name">{{model.name}}</span>
          <span class="mg-l-10 c-c" v-else>选择模版</span>
        </div>
        <div class="title">请选择字段间的对应关系</div>
        <div class="file-content">
          <div class="file-item" v-for="modelItem,index in modelList" :key="modelItem.modelId">
            <div class="item-name">
              <div class="name">{{modelItem.name}}</div>
              <div class="name mg-l-70">{{model.name}}</div>
            </div>
            <div class="item-content" v-for="file in modelItem.flds" :key="file.ord">
              <div class="file-name">
                <div class="name">{{file.name}}</div>
                <div class="arrow">→</div>
              </div>
              <el-select v-model="transferObjList[index]['transfer'][file.name]" placeholder="请选择" value-key="name">
                <el-option v-for="item in model.flds" :key="item.ord" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="_modelChangeVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmitClick">确 定</el-button>
      </span>
    </el-dialog>
    <select-model ref="selectModel" @selectModel="selectModel" @openImportAnki="onImportAnkiClick"
      :showSelectModel.sync="isShowSelectModel" @updateShowSelectModel="updateShowSelectModel" v-if="isShowSelectModel">
    </select-model>
  </div>
</template>
<script>
import request from '../../../libs/api.request';
import { showMessage } from '../../../libs/message';
import SelectModel from '../../SelectModel.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    modelChangeVisible: {
      type: Boolean,
      default: false
    },
    // 选中的卡片的模版ID
    selectModelIdList: {
      type: Array,
      default: () => []
    },
    // 选中的卡片id
    batchSelectedCard: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SelectModel
  },
  data() {
    return {
      isShowSelectModel: false,
      modelList: [], // 模版列表
      transferObjList: [], // 绑定的转换对象的集合
      model: {}, // 要更换后的模版
    }
  },
  methods: {
    onSubmitClick() {
      if(!this.model.modelId) return showMessage({message:'请选择更换后的模版',type:'warning'})
      this.$confirm(`以上操作将更换${this.batchSelectedCard.length}张卡片的模版，是否确定更换？`, '更换模版', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done();
            let loading = this.$loading({text:'模版更换中...'})
            request.request({
              url: '/card/changeModel',
              method: 'post',
              data: {
                cardIds: this.batchSelectedCard,
                map: JSON.stringify(this.transferObjList)
              }
            }).then(res => {
              loading.close()
              this.$emit('changeModelSuccess')
              showMessage({message:"模版更换成功", type:'success'})
              this.$store.commit('setSelectAll',false)
              this._modelChangeVisible = false
            }).catch(e => {
              done();
              loading.close()
              showMessage({ message: e.message, type: 'error' })
            })
          } else {
            done();
          }
        }
      })
    },
    getModelDetail() {
      console.log(this.selectModelIdList);
      this.modelList = this.getModelList.filter(e => {
        return this.selectModelIdList.includes(e.modelId)
      })
      // 构造transferobj
      this.modelList.forEach(v => {
        let obj = {
          from: '',
          to: '',
          transfer: {}
        }
        obj.from = v.modelId
        v.flds.forEach(fld => {
          obj.transfer[fld.name] = ''
        })
        this.transferObjList.push(obj)
      })
    },
    updateShowSelectModel(v) {
      this.isShowSelectModel = v
    },
    onImportAnkiClick() { },
    onOpenSelectModel() {
      this.isShowSelectModel = true
    },
    selectModel(model) {
      console.log(model);
      this.model = model
      this.transferObjList.forEach(e => {
        e.to = model.modelId
        let index = 0
        for (let key in e.transfer) {
          if (model.flds[index] !== undefined && model.flds[index].name) {
            e.transfer[key] = model.flds[index].name
          } else {
            e.transfer[key] = ''
          }
          index++
        }
      })
    },
    onSaveClick() {
      console.log(this.tmpls);
      if (this.modelName.trim() == '') return showMessage({ message: '模版名称不能为空', type: 'error' })
      if (this.flds.length === 0) return showMessage({ message: '至少需要一个字段', type: 'error' })
      let desc = '', title = ''
      if (this.model.modelId) {
        if (this.cardNum === 0) {
          desc = '确定修改模版？'
        } else {
          desc = '以上修改将影响' + this.cardNum + '张卡片,是否确定修改？'
        }
        title = '修改模版'
      } else {
        desc = '确定创建新的模版？'
        title = '创建模版'
      }
      this.$confirm(desc, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/model/saveModel',
              method: 'post',
              data: {
                modelId: this.model.modelId || new Date().getTime() + '001',
                tmpls: JSON.stringify(this.tmpls),
                flds: JSON.stringify(this.flds),
                css: this.css,
                name: this.modelName
              }
            }).then(res => {
              this.$store.dispatch('getModelList')
              if (this.model.modelId) {
                showMessage({ message: '修改成功！', type: 'success' })
              } else {
                showMessage({ message: '创建成功！', type: 'success' })
              }
              this._modelVisible = false
              done();
            }).catch(e => {
              done();
              showMessage({ message: e.message, type: 'error' })
            })
          } else {
            done();
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters(['getModelList']),
    _modelChangeVisible: {
      get() {
        return this.modelChangeVisible
      },
      set(v) {
        this.$emit('updateModelChangeVisible', v)
      }
    }
  },
  mounted() {
    this.getModelDetail()
  }
}
</script>

<style scoped lang="scss">
.model-change_box {
  .title {
    font-size: 16px;
    color: #202020;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .select-model {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .file-content {
    .file-item {
      margin-top: 20px;

      .item-name {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .name {
          flex: 1;
          font-size: 16px;
          color: #202020;
          font-weight: 600;
          opacity: .6;
        }
      }

      .item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .file-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #202020;
          flex: 1;

          .name {
            width: max-content;
          }

          .arrow {
            margin-right: 40%;
          }
        }
      }
    }
  }
}

::v-deep .model-dialog {
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}

::v-deep .model-dialog .el-dialog__body {
  padding: 12px 24px;
  height: 528px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>